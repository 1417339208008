import * as actionTypes from "../../actions/actionType";

const initialState = {
  error: {},
  list_loading: false,
  post_loading: false,
  list_result: new Array(),
  post_result: {},
  delete_loading: false,
  delete_result: {}
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_BUNDLE_LIST_INIT:
      return { ...state, list_loading: true, error: "", list_result: new Array() };
    case actionTypes.GET_BUNDLE_LIST_FAILURE:
      return { ...state, list_loading: false, error: action.payload };
    case actionTypes.GET_BUNDLE_LIST_SUCCESS:
      return { ...state, list_loading: false, list_result: action.payload };
    case actionTypes.POST_BUNDLE_INIT:
      return { ...state, post_loading: true, error: "" };
    case actionTypes.POST_BUNDLE_FAILURE:
      return { ...state, post_loading: false, error: action.payload };
    case actionTypes.POST_BUNDLE_SUCCESS:
      return { ...state, post_loading: false, post_result: action.payload };
    case actionTypes.DELETE_BUNDLE_INIT:
      return { ...state, delete_loading: true, error: "" };
    case actionTypes.DELETE_BUNDLE_FAILURE:
      return { ...state, delete_loading: false, error: action.payload };
    case actionTypes.DELETE_BUNDLE_SUCCESS:
      return { ...state, delete_loading: false, delete_result: action.payload };
    default:
      return state;
  }
};

export default reducer;
