import * as actionTypes from "../../actions/actionType";

const initialState = {
  errorMessage: "",
  loading: false,
  result: "",
  accessRole: -1,
  successfulLoggedIn: "",
  successfulLogOut: "",
  checkLoginStatus: 0,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_INIT:
      return { ...state, loading: true, accessRole: -1, successfulLoggedIn: "", successfulLogOut: "" };
    case actionTypes.LOGIN_FAILURE:
      return { ...state, loading: false, accessRole: -1, successfulLoggedIn: "NO", successfulLogOut: "NO" };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, loading: false, accessRole: action.result, successfulLoggedIn: "YES", token: action.token, successfulLogOut: "NO" };
    case actionTypes.CHECK_LOGIN_INIT:
      return { ...state, accessRole: -1, checkLoginStatus: 0 };
    case actionTypes.CHECK_LOGIN_FAILURE:
      return { ...state, accessRole: -1, checkLoginStatus: 1 };
    case actionTypes.CHECK_LOGIN_SUCCESS:
      return { ...state, accessRole: action.result,  checkLoginStatus: 2 };
    case actionTypes.LOGOUT_INIT:
      return { ...state, loading: true, accessRole: -1, successfulLogOut: "NO" };
    case actionTypes.LOGOUT_FAILURE:
      return { ...state, loading: false, accessRole: -1, successfulLogOut: "YES" };
    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, loading: false, successfulLogOut: "YES" };
    default:
      return state;
  }
};

export default reducer;
