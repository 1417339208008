import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const deleteCacheInit = () => {
  return {
    type: actionType.DELETE_CACHE_INIT
  };
};

const deleteCacheFailure = (err: any) => {
  return {
    type: actionType.DELETE_CACHE_FAILURE,
    payload: err
  };
};

const deleteCacheSuccess = (result: any) => {
  return {
    type: actionType.DELETE_CACHE_SUCCESS,
    payload: result
  };
};

const clearCacheMsg = () => {
  return {
    type: actionType.CLEAR_CACHE_MSG
  };
};

export const deleteCache = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(deleteCacheInit());

    bookAPI
      .deleteCache(postData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(deleteCacheSuccess(result.data));
        } else {
          dispatch(deleteCacheFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(deleteCacheFailure(err));
      });
  };
};

export const clearCasheMessage = (): any => {
  return (dispatch: any) => {
    dispatch(clearCacheMsg());
  };
};
