import React from "react";
import globalFunction from "../../util/globalFunctions";
import "./externalOrder.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { styled } from '@mui/material/styles';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



const Home: React.FC = () => {
    const glob = new globalFunction();
    const dispatch = useDispatch();

    const onGetExOrderClick = () => {
        const data = {
            page_number: 0,
            page_size: 100,
        }
        dispatch(actions.getExternalOrders(data));
    };

    const exOrderList = useSelector((state: any) => {
        return state.externalOrderReducer.exOrderList;
    });

    const exOrderLoading = useSelector((state: any) => {
        return state.externalOrderReducer.exOrderLoading;
    });

    return (
        <Container fluid={true} className="container_div">
            {(exOrderLoading) && <LoadingModal showModal={true} />}
            <Row>
                <Col>
                    <h1>External Orders</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={onGetExOrderClick}>Get List</Button>
                </Col>
            </Row>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>ES ID</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell align="left">Source ID</StyledTableCell>
                            <StyledTableCell align="left">Source</StyledTableCell>
                            <StyledTableCell align="left">IMG</StyledTableCell>
                            <StyledTableCell align="left">Info</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {exOrderList.length > 0 && exOrderList?.map((row) => (
                            <StyledTableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell>{row.id}</StyledTableCell>
                                <StyledTableCell> {moment(row.creation_time).format("YYYY-MM-DD")}</StyledTableCell>
                                <StyledTableCell>{row.source_id}</StyledTableCell>
                                <StyledTableCell>{row.source}</StyledTableCell>
                                <StyledTableCell>
                                    <img src={row.book_image} className="book_image" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.book_title}
                                </StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Container>
    );
};

export default Home;
