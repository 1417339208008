import * as React from "react";

import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import "./loadingModal.css";

interface IProps {
  showModal: boolean;
}

const LoadingModal: React.FC<IProps> = (props: any) => {
  return (
    <Modal isOpen={props.showModal}>
      <ModalBody>
        <div style={{ textAlign: "center" }}>
          <Spinner style={{ width: "3rem", height: "3rem", margin: 40 }} />{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LoadingModal;
