import * as actionTypes from "../../actions/actionType";

const initialState = {
    error: {},
    loading_loading: false,
    get_voucher_result: {},
    post_voucher_result: {},
    post_voucher_st_result: {}
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.GET_VOUCHER_INIT:
            return { ...state, loading_loading: true, error: "", get_voucher_result: {} };
        case actionTypes.GET_VOUCHER_FAILURE:
            return { ...state, loading_loading: false, error: action.payload };
        case actionTypes.GET_VOUCHER_SUCCESS:
            return { ...state, loading_loading: false, get_voucher_result: action.payload };
        case actionTypes.POST_VOUCHER_INIT:
            return { ...state, loading_loading: true, error: "", post_voucher_result: {} };
        case actionTypes.POST_VOUCHER_FAILURE:
            return { ...state, loading_loading: false, error: action.payload };
        case actionTypes.POST_VOUCHER_SUCCESS:
            return { ...state, loading_loading: false, post_voucher_result: action.payload };
        case actionTypes.POST_VOUCHER_ST_INIT:
            return { ...state, loading_loading: true, error: "", post_voucher_st_result: {} };
        case actionTypes.POST_VOUCHER_ST_FAILURE:
            return { ...state, loading_loading: false, error: action.payload };
        case actionTypes.POST_VOUCHER_ST_SUCCESS:
            return { ...state, loading_loading: false, post_voucher_st_result: action.payload };
        default:
            return state;
    }
};

export default reducer;