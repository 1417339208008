import * as actionTypes from "../../actions/actionType";

const initialState = {
  error: {},
  user_loading: false,
  result: null,
  update_loading: false,
  userinfo_result: null,
  userpass_result: null
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_USER_INIT:
      return { ...state, user_loading: true, error: "", result: null };
    case actionTypes.GET_USER_FAILURE:
      return { ...state, user_loading: false, error: action.payload };
    case actionTypes.GET_USER_SUCCESS:
      return { ...state, user_loading: false, result: action.payload };
    case actionTypes.UPDATE_USER_INFO_INIT:
      return { ...state, update_loading: true, error: "", userinfo_result: null };
    case actionTypes.UPDATE_USER_INFO_FAILURE:
      return { ...state, update_loading: false, error: action.payload };
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return { ...state, update_loading: false, userinfo_result: action.payload };
    case actionTypes.UPDATE_USER_PASS_INIT:
      return { ...state, update_loading: true, error: "", userpass_result: null };
    case actionTypes.UPDATE_USER_PASS_FAILURE:
      return { ...state, update_loading: false, error: action.payload };
    case actionTypes.UPDATE_USER_PASS_SUCCESS:
      return { ...state, update_loading: false, userpass_result: action.payload };
    default:
      return state;
  }
};

export default reducer;
