import * as React from "react";
// import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import * as actions from "../../../store/actions/index";
import LoadingModal from "../../../components/ui/loadingModal/loadingModal";
import MessageModal from "../../../components/ui/messageModal/messageModal";
import { Navigate, useParams } from "react-router-dom";


// import { Field, Form, Formik, FormikProps } from "formik";

interface IUrlObj {
    bookISBN: string;
}

interface IProps {
    match: any;
}

interface IPreviewObject {
    book_image: string,
    book_isbn: string,
    book_title: string,
    display_price: string,
    book_discount_rate: number,
    gbi_base_price: number,
    gbi_currency: string,
    gbbi_points: number,
    gb_uid: string,
    family_key: string,
    gbbi_tradein_price: number,
    buyer_price: number,
    buyer_discount: number
}

const initPreviewValue = {
    book_image: "",
    book_isbn: "",
    book_title: "",
    display_price: "",
    book_discount_rate: 0,
    gbi_base_price: 0,
    gbi_currency: "",
    gbbi_points: 0,
    gb_uid: "",
    family_key: "",
    gbbi_tradein_price: 0,
    buyer_price: 0,
    buyer_discount: 0
}

const BatchItem: React.FC = () => {

    const { userId, batchId } = useParams();
    const [urlUserID, setUrlUserID] = React.useState(userId);
    const [urlBatchId, setUrlBatchId] = React.useState(batchId);
    // const [urlUserID, setUrlUserID] = React.useState((match && match.params && match.params.userId ? match.params.userId : ''));
    // const [urlBatchId, setUrlBatchId] = React.useState((match && match.params && match.params.batchId ? match.params.batchId : ''));
    const [addCondition, setAddCondition] = React.useState("");
    const [itemSearchType, setItemSearchType] = React.useState("");
    const [itemSearchKey, setItemSearchKey] = React.useState("");
    const [pageBatchId, setPageBatchId] = React.useState("");
    const [showPreview, setShowPreview] = React.useState(false);
    const [previewObj, setPreviewObj] = React.useState<IPreviewObject>(initPreviewValue);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [totalGBPoints, setTotalGBPoints] = React.useState(0);
    const [redirectToTradein, setRedirectToTradein] = React.useState(false);


    //START: State Selector
    const dispatch = useDispatch();
    const pageLoadingS = useSelector((state: any) => {
        return state.tradeinReducer.loading_loading;
    });
    const batchIdS = useSelector((state: any) => {
        return state.tradeinReducer.get_batchid_result;
    });
    const itemSearchResult = useSelector((state: any) => {
        return state.tradeinReducer.get_add_sea_btc_itm_result;
    });
    const batchItems = useSelector((state: any) => {
        return state.tradeinReducer.get_btc_itm_result;
    });
    const deleteBatchItems = useSelector((state: any) => {
        return state.tradeinReducer.delete_btc_itm_result;
    });
    const postBatchItemS = useSelector((state: any) => {
        return state.tradeinReducer.post_btc_itm_result;
    });
    const postCnfResult = useSelector((state: any) => {
        return state.tradeinReducer.post_btc_cnf_status_result;
    });
    //END: State Selector
    const addBatchItemConditionHandler = (event: any) => {
        setAddCondition(event.target.value);
    };
    const addBatchItemSearchTypeHandler = (event: any) => {
        setItemSearchType(event.target.value);
    };
    const addBatchItemSearchKeyHandler = (event: any) => {
        setItemSearchKey(event.target.value);
    };
    const editPreviewInputs = (event: any) => {
        if (event.target.name === "tr_price") {
            setPreviewObj({
                ...previewObj,
                gbbi_tradein_price: event.target.value,
                // gbbi_points: (parseFloat(event.target.value) * 10)
            });
        }
        else if (event.target.name === "tr_base_price") {
            setPreviewObj({
                ...previewObj,
                gbi_base_price: event.target.value,
                buyer_discount: 0,
                buyer_price: 0,
            });
        }
        else if (event.target.name === "tr_currency") {
            setPreviewObj({
                ...previewObj,
                gbi_currency: event.target.value
            });
        }
        else if (event.target.name === "tr_points") {
            setPreviewObj({
                ...previewObj,
                gbbi_points: event.target.value
            });
        }
        else if (event.target.name === "uid_txt") {
            setPreviewObj({
                ...previewObj,
                gb_uid: event.target.value
            });
        }
        else if (event.target.name === "total_points") {
            setTotalGBPoints(event.target.value);
        }
    };

    const hideSearchBatchItemPreview = () => {
        setShowPreview(false);
        // setPreviewObj(initPreviewValue);
    }

    const batchItemSearchAction = () => {
        setShowPreview(false);
        let data = {
            type: itemSearchType,
            key: itemSearchKey,
            second_key: addCondition
        };
        dispatch(
            actions.getBatchItemSearch(data)
        );
    }
    const deleteBatchItemAction = (item_id) => {
        setShowPreview(false);
        let data = {
            gbbi_id: item_id
        };
        dispatch(
            actions.deleteBatchItem(data)
        );
    }
    const postBatchCnfStatus = () => {
        setShowPreview(false);
        let data = {
            gbb_id: pageBatchId,
            gbb_completed: 1,
            user_green_points: totalGBPoints,
            user_id: urlUserID
        };
        dispatch(
            actions.postBatchCnfStatus(data)
        );
    }
    const batchItemAddAction = () => {
        let data = {
            // Batch Item
            gbb_id: pageBatchId,
            gb_uid: previewObj.gb_uid,
            gbbi_condition: addCondition,
            gbbi_tradein_price: previewObj.gbbi_tradein_price,
            gbbi_currency: previewObj.gbi_currency,
            gbbi_points: previewObj.gbbi_points,
            gbbi_status: "1",
            // inventory
            book_condition: addCondition,
            book_isbn: previewObj.book_isbn,
            gbi_base_price: previewObj.gbi_base_price,
            gbi_currency: previewObj.gbi_currency,
            family_key: previewObj.family_key,
            //GB Book                         
            gb_status: "1",
            user_id: urlUserID,
        };
        if (!data.gb_uid && data.gb_uid.length === 0 &&
            !data.gb_uid && data.gb_uid.length === 0) {
            showModalHandler("Please provide all required fields!");
        }
        else {
            hideSearchBatchItemPreview();
            dispatch(
                actions.postBatchItem(data)
            );
        }
    }
    const getBatchIdAPI = () => {
        dispatch(
            actions.getBatchId({
                batch_id: urlBatchId,
                user_id: urlUserID
            })
        );
    }
    const getBatchItemsAPI = (id) => {
        if (id) {
            dispatch(
                actions.getBatchItems({
                    batch_id: id
                })
            );
        }
    }

    React.useEffect(() => {
        //get batch ID for the user
        getBatchIdAPI();
    }, []);
    React.useEffect(() => {
        //Sum Total Points        
        let count = 0;
        Object.keys(batchItems).map(index => {
            count = count + batchItems[index].gbbi_points;
        });
        setTotalGBPoints(count);
    }, [batchItems]);
    React.useEffect(() => {
        if (postBatchItemS) {
            getBatchItemsAPI(pageBatchId);
        }
    }, [postBatchItemS]);
    React.useEffect(() => {
        if (deleteBatchItems) {
            getBatchItemsAPI(pageBatchId);
        }
    }, [deleteBatchItems]);
    React.useEffect(() => {
        if (batchIdS) {
            setPageBatchId(batchIdS.gbb_id);
            getBatchItemsAPI(batchIdS.gbb_id);
        }
    }, [batchIdS]);
    React.useEffect(() => {
        if (postCnfResult && postCnfResult.user_id && postCnfResult.user_name) {
            alert("Batch Is Confirmed and Emailed :)");
            // window.open('/admin/batch/');
            // window.location.href = "/admin/batch/";
            setRedirectToTradein(true);
        }
    }, [postCnfResult]);
    React.useEffect(() => {
        if (Object.keys(itemSearchResult).length != 0) {
            setPreviewObj({
                ...setPreviewObj,
                book_isbn: itemSearchResult.book_isbn,
                family_key: itemSearchResult.family_key,
                book_image: itemSearchResult.book_image,
                book_title: itemSearchResult.book_title,
                display_price: itemSearchResult.display_price,
                book_discount_rate: itemSearchResult.book_discount_rate,
                gbi_base_price: itemSearchResult.gbi_base_price,
                gbi_currency: itemSearchResult.gbi_currency,
                gbbi_points: itemSearchResult.gbbi_points,
                gb_uid: itemSearchResult.gb_uid,
                gbbi_tradein_price: itemSearchResult.gbbi_tradein_price,
                buyer_discount: itemSearchResult.buyer_discount,
                buyer_price: itemSearchResult.buyer_price
            });
            setShowPreview(true);
        }
    }, [itemSearchResult]);

    const showModalHandler = (message: string) => {
        setMessage(message);
        setShowMessage(true);
    }

    const closeModalHandler = () => {
        setMessage("");
        setShowMessage(false);
    };

    return (
        <Container fluid={true} className="container_div">
            {redirectToTradein && <Navigate to={"/batch/"} />}
            <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
            {pageLoadingS && <LoadingModal showModal={true} />}
            <Row>
                <Col>
                    <h1>New Batch Item</h1>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col xs="6" md="4">
                        <Label>BOOKURVE UID/ ISBN</Label>
                        <div className="input-group mb-3">
                            <select
                                className="form-control"
                                onChange={addBatchItemSearchTypeHandler}
                            >
                                <option defaultValue="">Select...</option>
                                <option value="uid">UID</option>
                                <option value="isbn">ISBN</option>
                            </select>
                            <Input
                                type="text"
                                className="form-control"
                                name="user_id_txt"
                                id="userId_id"
                                placeholder="UID / ISBN"
                                value={itemSearchKey}
                                onChange={addBatchItemSearchKeyHandler}
                            />
                        </div>
                    </Col>
                    <Col xs="6" md="4">
                        <FormGroup>
                            <Label>Condition</Label>
                            <select
                                className="form-control"
                                onChange={addBatchItemConditionHandler}
                            >
                                <option defaultValue="">Select...</option>
                                <option value="a">Very Good</option>
                                <option value="b">Good</option>
                                <option value="c">Readable</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col xs="6" md="4">
                        <Button style={{ marginTop: 30 }} color="primary" block onClick={batchItemSearchAction}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
            <hr />
            {showPreview && (
                <Row style={{ border: "solid 1px gray", borderRadius: 10, padding: "10px 0px", background: "#eee" }}>
                    <Col md="1">
                        <img src={previewObj.book_image} alt="" style={{ width: "100%" }} />
                    </Col>
                    <Col md="2">
                        <div>{previewObj.book_title}</div>
                        <div>Web Price: {previewObj.display_price}</div>
                        <div>Web Disc.: {previewObj.book_discount_rate}%</div>
                    </Col>
                    <Col md="9">
                        <Row>
                            <Col md="4">
                                <Label>Unique ID</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="uid_txt"
                                    id="uid_id"
                                    placeholder="UID"
                                    value={previewObj.gb_uid}
                                    onChange={editPreviewInputs}
                                />
                            </Col>
                            <Col md="4">
                                <Label>Base-Price</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="tr_price"
                                    id="tr_price_id"
                                    placeholder="Price"
                                    value={previewObj.gbi_base_price}
                                    onChange={editPreviewInputs}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Label>Buyer Price</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="tr_buyer_price"
                                    id="tr_buyer_price_id"
                                    placeholder="Price"
                                    value={previewObj.buyer_price}
                                    disabled
                                />
                            </Col>
                            <Col md="4">
                                <Label>Buyer Discount</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="tr_buyer_discount"
                                    id="tr_buyer_discount_id"
                                    placeholder="Price"
                                    value={previewObj.buyer_discount}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Label>Trade-in Price</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="tr_price"
                                    id="tr_price_id"
                                    placeholder="Price"
                                    value={previewObj.gbbi_tradein_price}
                                    onChange={editPreviewInputs}
                                />
                            </Col>
                            <Col md="4">
                                <Label>Trade-in Currency</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="tr_currency"
                                    id="tr_currency_id"
                                    placeholder="Currency"
                                    value={previewObj.gbi_currency}
                                    onChange={editPreviewInputs}
                                />
                            </Col>
                            <Col md="4">
                                <Label>Trade-in Points</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="tr_points"
                                    id="tr_points_id"
                                    placeholder="Points"
                                    value={previewObj.gbbi_points}
                                    onChange={editPreviewInputs}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="3">
                                <Button style={{ marginTop: 30 }} color="primary" block onClick={batchItemAddAction}>Save</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            <div>
                {Object.keys(batchItems).map(index => {
                    return (
                        <Row>
                            <Col md="2">
                                <img src={batchItems[index].book_image} alt="" style={{ width: "100%" }} />
                            </Col>
                            <Col md="9">
                                <div>{batchItems[index].book_title}</div>
                                <div>Web ISBN: {batchItems[index].book_isbn}</div>
                                <div>Web Price: {batchItems[index].display_price}</div>
                                <div>Web Disc.: {batchItems[index].book_discount_rate}%</div>
                                <div>UID: {batchItems[index].gb_uid}</div>
                                <div>Trade-in Price: {batchItems[index].gbbi_currency}-{batchItems[index].gbbi_tradein_price}</div>
                            </Col>
                            <Col md="1">
                                <Button color="danger" onClick={() => { deleteBatchItemAction(batchItems[index].gbbi_id) }}>X</Button>
                            </Col>
                        </Row>
                    )
                })}
            </div>
            <Row>
                <Col xs="3">
                    <Label>Total Trade-in Points</Label>
                    <Input
                        type="text"
                        className="form-control"
                        name="total_points"
                        id="total_points_id"
                        placeholder="Points"
                        value={totalGBPoints}
                        onChange={editPreviewInputs}
                    />
                </Col>
                <Col xs="3">
                    <Button color="success" style={{ marginTop: 30 }} onClick={postBatchCnfStatus}>Confirm & Email</Button>
                </Col>
                <Col></Col>
            </Row>
        </Container>

    );
};

export default BatchItem;
