import * as React from "react";
// import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import * as actions from "../../store/actions/index";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";
import MessageModal from "../../components/ui/messageModal/messageModal";
import { Navigate } from "react-router-dom";
import sha256 from 'crypto-js/sha256';

import axios from "axios";

// import { Field, Form, Formik, FormikProps } from "formik";



const Stock: React.FC = () => {

    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [authLink, setAuthLink] = React.useState("");

    const dispatch = useDispatch();
    const userSearchResult = useSelector((state: any) => {
        return state.userReducer.result;
    });

    React.useEffect(() => {
        // const urlObj = match.params;
    }, []);

    const authenticationHandler = () => {
        let partnerKey = "71609fb8ced5cb0799cb796ae7be4b47af0e25dfb7b9c8ff3ecbad206a518a75";

        let redirectURL = "http://localhost:3000/admin/stock/";

        let tokenBaseTxt = partnerKey + redirectURL;
        let token = sha256(tokenBaseTxt);
        // let link = `https://partner.uat.shopeemobile.com/api/v1/shop/auth_partner?id=843961&token=${token}&redirect=${redirectURL}`;
        let link = `https://partner.test-stable.shopeemobile.com/api/v2/shop/auth_partner?id=843961&token=${token}&redirect=${redirectURL}`;
        setAuthLink(link);

    }

    const getShopDetails = () => {
        let partnerId = "843961";

        // https://partner.shopeemobile.com/api/v1/shop/get
        // alert(JSON.stringify(match));
        // const url = new URLSearchParams(useLocation().search);

        const query = new URLSearchParams(window.location.search);
        let shopId = query.get('shop_id');
        let currentTimestamp = Date.now();

        let shopLink = `https://partner.uat.shopeemobile.com/api/v1/shop/get`;


        // ?partner_id=${partnerId}&shopid=${shopId}&timestamp=${Date.now()}
        let data = {
            partner_id: partnerId,
            shopid: shopId,
            timestamp: currentTimestamp
        }

        let token = sha256(shopLink + "|" + data);

        return axios
            .post(shopLink, data, {
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((response: any) => {
                alert(JSON.stringify(response));
                // return response.data;
            })
            .catch((error: any) => {
                alert(JSON.stringify(error));
                throw error;
            });

        //https://partner.uat.shopeemobile.com/api/v1/shop/get?partner_id=843961&shopid=220288174&timestamp=1614692582628
    }

    return (
        <Container fluid={true} className="container_div">
            <Row>
                <Col>
                    <h1>Stock</h1>
                    <Button onClick={authenticationHandler}>Auth</Button>
                    {authLink != "" && (
                        <a href={authLink} target="_blank">click to go</a>
                    )}
                    <Button onClick={getShopDetails}>Get Shop Details</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Stock;
