import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getSaleListInit = () => {
  return {
    type: actionType.GET_SALE_LIST_INIT
  };
};

const getSaleListFailure = (err: any) => {
  return {
    type: actionType.GET_SALE_LIST_FAILURE,
    payload: err
  };
};

const getSaleListSuccess = (result: any) => {
  return {
    type: actionType.GET_SALE_LIST_SUCCESS,
    payload: result
  };
};

const postSaleInit = () => {
  return {
    type: actionType.POST_SALE_INIT
  };
};

const postSaleFailure = (err: any) => {
  return {
    type: actionType.POST_SALE_FAILURE,
    payload: err
  };
};

const postSaleSuccess = (result: any) => {
  return {
    type: actionType.POST_SALE_SUCCESS,
    payload: result
  };
};

const deleteSaleInit = () => {
  return {
    type: actionType.DELETE_SALE_INIT
  };
};

const deleteSaleFailure = (err: any) => {
  return {
    type: actionType.DELETE_SALE_FAILURE,
    payload: err
  };
};

const deleteSaleSuccess = (result: any) => {
  return {
    type: actionType.DELETE_SALE_SUCCESS,
    payload: result
  };
};

export const getSaleList = (): any => {
  return (dispatch: any) => {
    dispatch(getSaleListInit());

    bookAPI
      .getSaleList()
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getSaleListSuccess(result.data));
        } else {
          dispatch(getSaleListFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getSaleListFailure(err));
      });
  };
};

export const postSale = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(postSaleInit());

    bookAPI
      .postSale(postData)
      .then((result: any) => {
        if (result && result.status === "success") {
          dispatch(postSaleSuccess(result.data));
        } else {
          dispatch(postSaleFailure("Server Error!"));
        }
      })
      .catch((err: any) => {
        dispatch(postSaleFailure("Server Error!"));
      });
  };
};

export const deleteSale = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(deleteSaleInit());

    bookAPI
      .deleteSale(postData)
      .then((result: any) => {
        if (result && result.status === "success") {
          dispatch(deleteSaleSuccess(result.data));
        } else {
          dispatch(deleteSaleFailure("Server Error!"));
        }
      })
      .catch((err: any) => {
        dispatch(deleteSaleFailure("Server Error!"));
      });
  };
};
