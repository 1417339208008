import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getExternalOrdersInit = () => {
    return {
        type: actionType.GET_EX_ORDER_INIT
    };
};

const getExternalOrdersFailure = (err: any) => {
    return {
        type: actionType.GET_EX_ORDER_FAILURE,
        payload: err
    };
};

const getExternalOrdersSuccess = (result: any) => {
    return {
        type: actionType.GET_EX_ORDER_SUCCESS,
        payload: result
    };
};

const clearExternalOrdersData = () => {
    return {
        type: actionType.CLEAR_EX_ORDER
    };
};

export const getExternalOrders = (data: any): any => {
    return (dispatch: any) => {
        dispatch(getExternalOrdersInit());

        bookAPI
            .getExternalOrders(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(getExternalOrdersSuccess(result.data));
                } else {
                    dispatch(getExternalOrdersFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(getExternalOrdersFailure(err));
            });
    };
};

export const clearExternalOrders = (): any => {
    return (dispatch: any) => {
        dispatch(clearExternalOrdersData());
    };
};
