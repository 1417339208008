import * as actionTypes from "../../actions/actionType";

const initialState = {
  error: "",
  loading: false,
  result: "",
  updateBook: "",
  searchBookResult: null,
  searchBookLoading: false,
  categoryListResult: null,
  categoryListLoading: false,
  updateBkDetailsResult: null,
  updateBkDetailsLoading: false,
  getBookFlagsResult: null,
  getBookFlagsLoading: false,
  postBookFlagsResult: null,
  postBookFlagsLoading: false,
  postLightSearchResult: null,
  postLightSearchLoading: false
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.POST_BOOK_INIT:
      return { ...state, loading: true };
    case actionTypes.POST_BOOK_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.POST_BOOK_SUCCESS:
      return { ...state, loading: false, result: action.payload };
    case actionTypes.UPDATE_CUSTOM_BOOK_INIT:
      return { ...state, loading: true };
    case actionTypes.UPDATE_CUSTOM_BOOK_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.UPDATE_CUSTOM_BOOK_SUCCESS:
      return { ...state, loading: false, updateBook: action.payload };
    case actionTypes.SEARCH_BOOK_INIT: //Book Search
      return { ...state, searchBookLoading: true, searchBookResult: null };
    case actionTypes.SEARCH_BOOK_FAILURE:
      return { ...state, searchBookLoading: false, searchBookResult: null, error: action.payload };
    case actionTypes.SEARCH_BOOK_SUCCESS:
      return { ...state, searchBookLoading: false, searchBookResult: action.payload };
    case actionTypes.BOOK_CATEGORY_LIST_INIT: //Book Category List
      return { ...state, categoryListLoading: true, categoryListResult: null };
    case actionTypes.BOOK_CATEGORY_LIST_FAILURE:
      return { ...state, categoryListLoading: false, categoryListResult: false, error: action.payload };
    case actionTypes.BOOK_CATEGORY_LIST_SUCCESS:
      return { ...state, categoryListLoading: false, categoryListResult: action.payload };
    case actionTypes.UPDATE_BOOK_INIT: //Book Details Update
      return { ...state, updateBkDetailsLoading: true, updateBkDetailsResult: null };
    case actionTypes.UPDATE_BOOK_FAILURE:
      return { ...state, updateBkDetailsLoading: false, updateBkDetailsResult: 'failed', error: action.payload };
    case actionTypes.UPDATE_BOOK_SUCCESS:
      return { ...state, updateBkDetailsLoading: false, updateBkDetailsResult: 'updated', searchBookResult: action.payload };
    case actionTypes.UPDATE_BOOK_RESET:
      return { ...state, updateBkDetailsLoading: false, updateBkDetailsResult: null };
    case actionTypes.GET_FLAG_INIT: //Book Flags
      return { ...state, getBookFlagsLoading: true, getBookFlagsResult: null };
    case actionTypes.GET_FLAG_FAILURE:
      return { ...state, getBookFlagsLoading: false, getBookFlagsResult: null, error: action.payload };
    case actionTypes.GET_FLAG_SUCCESS:
      return { ...state, getBookFlagsLoading: false, getBookFlagsResult: action.payload };
    case actionTypes.POST_FLAG_INIT: //POST Book Flags
      return { ...state, postBookFlagsLoading: true, postBookFlagsResult: null };
    case actionTypes.POST_FLAG_FAILURE:
      return { ...state, postBookFlagsLoading: false, postBookFlagsResult: null, error: action.payload };
    case actionTypes.POST_FLAG_SUCCESS:
      return { ...state, postBookFlagsLoading: false, postBookFlagsResult: action.payload };
    case actionTypes.POST_LIGHT_SEARCH_INIT: //POST Light Search
      return { ...state, postLightSearchLoading: true, postLightSearchResult: null };
    case actionTypes.POST_LIGHT_SEARCH_FAILURE:
      return { ...state, postLightSearchLoading: false, postLightSearchResult: null, error: action.payload };
    case actionTypes.POST_LIGHT_SEARCH_SUCCESS:
      return { ...state, postLightSearchLoading: false, postLightSearchResult: action.payload };
    default:
      return state;
  }
};

export default reducer;
