import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";

const bookAPI = new BookApi();

const loginInit = () => {
  return {
    type: actionType.LOGIN_INIT
  };
};

const loginFailed = () => {
  return {
    type: actionType.LOGIN_FAILURE
  };
};

const loginSuccess = (access_role: any, token: any) => {
  return {
    type: actionType.LOGIN_SUCCESS,
    result: access_role,
    token: token
  };
};

export const login = (data: any): any => {
  return (dispatch: any) => {
    dispatch(loginInit());

    bookAPI
      .postUserSecurePhrase(data)
      .then((result: any) => {        
        if (result && result.status === "success" && result.data !== false) {
          dispatch(loginSuccess(result.data.access_role, result.data.token));
        } else {
          dispatch(loginFailed());
        }
      })
      .catch((err: any) => {
        dispatch(loginFailed());
      });
  };
};

const logoutInit = () => {
  return {
    type: actionType.LOGOUT_INIT
  };
};

const logoutFailed = () => {
  return {
    type: actionType.LOGOUT_FAILURE
  };
};

const logoutSuccess = () => {
  return {
    type: actionType.LOGOUT_SUCCESS
  };
};

export const logout = (): any => {
  return (dispatch: any) => {
    dispatch(logoutInit);

    bookAPI
      .logout()
      .then((result: any) => {   
           
        if (result && result.status === "success" && result.data !== false) {
          dispatch(logoutSuccess());
        } else {
          dispatch(logoutFailed);
        }
      })
      .catch((err: any) => {
        dispatch(logoutFailed());
      });
  };
};

const checkLoginInit = () => {
  return {
    type: actionType.CHECK_LOGIN_INIT
  };
};

const checkLoginFailed = () => {
  return {
    type: actionType.CHECK_LOGIN_FAILURE
  };
};

const checkLoginSuccess = (access_role: any) => {
  return {
    type: actionType.CHECK_LOGIN_SUCCESS,
    result: access_role
  };
};

export const checkLogin = (): any => {
  return (dispatch: any) => {
    dispatch(checkLoginInit());

    bookAPI
      .checkIsUserLogedIn()
      .then((result: any) => {        
        if (result && result.status === "success" && result.data !== false) {
          dispatch(checkLoginSuccess(result.data));
        } else {
          dispatch(checkLoginFailed());
        }
      })
      .catch((err: any) => {
        dispatch(checkLoginFailed());
      });
  };
};