import * as React from "react";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { Navigate } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import "./nav.css";
import { stat } from "fs";

const Navbar: React.FC = props => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies();

  const [soAnchor, setSoAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(soAnchor);
  const handleSoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSoAnchor(event.currentTarget);
  };
  const handleSoClose = () => {
    setSoAnchor(null);
  };


  const [isUserLogged, setIsUserLogged] = React.useState(false);
  const [userAccessLevel, setuserAccessLevel] = React.useState(-1);

  const loginStatus = useSelector((state: any) => {
    return state.authReducer.successfulLoggedIn;
  });

  const logOutStatus = useSelector((state: any) => {
    return state.authReducer.successfulLogOut;
  });

  const accessRole = useSelector((state: any) => {
    return state.authReducer.accessRole;
  });

  const checkLoginStatus = useSelector((state: any) => {
    return state.authReducer.checkLoginStatus;
  });

  React.useEffect(() => {
    dispatch(
      actions.checkLogin()
    );
  }, []);

  React.useEffect(() => {
    if (checkLoginStatus && checkLoginStatus == 2) {//logedin already
      setIsUserLogged(true);
      setuserAccessLevel(accessRole);
      navigateTo(true, accessRole);
    }
    else if (checkLoginStatus && checkLoginStatus == 1) { // not logedin      
      setIsUserLogged(false);
      setuserAccessLevel(-1);
      navigateTo(false, -1);
    }

  }, [checkLoginStatus]);

  React.useEffect(() => {
    if (loginStatus === "YES" && accessRole >= 0) {
      setIsUserLogged(true);
      setuserAccessLevel(accessRole);
      navigateTo(true, accessRole);
    } else if (loginStatus === "NO") {
      alert("Login FAILED!!!!");
    }
  }, [loginStatus]);

  React.useEffect(() => {
    if (logOutStatus === "YES") {
      setIsUserLogged(false);
      setuserAccessLevel(-1);
      navigateTo(false, -1);
    }
  }, [logOutStatus]);

  const navigateTo = (login: boolean, level: number) => {
    if (!login) {
      navigate("/login");
    }
    else {
      if (window.location.href.indexOf('login') > 0) {
        if (level === 0) {
          navigate("/");
        }
        else if (level === 1) {
          navigate("/add-new-book/");
        }
      }
    }
  }

  const logoutHandler = () => {
    dispatch(
      actions.logout()
    );
  }

  return (
    <React.Fragment>
      {isUserLogged && (
        <Nav className="top_nav">
          {userAccessLevel === 0 && (
            <React.Fragment>
              <NavItem>
                <Link to="/">
                  <div className="top_nav_item">Home</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/affiliate">
                  <div className="top_nav_item">Affiliate</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/order/">
                  <div className="top_nav_item">Orders</div>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/external-order/">
                  <div className="top_nav_item">Ex-Orders</div>
                </Link>
              </NavItem>
              <Button
                id="basic-button"                
                onClick={handleSoClick}
                sx={{color: "white"}}
              >
                Supplier Order
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={soAnchor}
                open={open}
                onClose={handleSoClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleSoClose}>Create Order</MenuItem>
                <MenuItem onClick={handleSoClose}>Searched Orders</MenuItem>
                <MenuItem onClick={handleSoClose}>Warehouse Listings</MenuItem>
              </Menu>
            </React.Fragment>
          )}
          <React.Fragment>
            <NavItem>
              <Link to="/voucher/">
                <div className="top_nav_item">Voucher</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/book">
                <div className="top_nav_item">Book</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/user/">
                <div className="top_nav_item">User</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/add-new-book/">
                <div className="top_nav_item">Add New Book</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/bundle/">
                <div className="top_nav_item">Bundle</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/flash-sale/">
                <div className="top_nav_item">Flash Sale</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/batch/">
                <div className="top_nav_item">Batch</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/stock/">
                <div className="top_nav_item">Stock</div>
              </Link>
            </NavItem>
            <NavItem>
              <div className="top_nav_logout" onClick={logoutHandler}>logout</div>
            </NavItem>
          </React.Fragment>
        </Nav>
      )}

      {!isUserLogged && (
        <Nav className="top_nav">
          <NavItem>
            <Link to="/login/">
              <div className="top_nav_item">Login</div>
            </Link>
          </NavItem>
        </Nav>
      )}
    </React.Fragment>
  );
};

export default Navbar;
