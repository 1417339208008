import * as React from "react";
// import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import dateFormat from "dateformat";
import { MdRefresh, MdArrowForward } from "react-icons/md";
import { Navigate } from "react-router-dom";

import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";

// import { Field, Form, Formik, FormikProps } from "formik";

interface IUrlObj {
    bookISBN: string;
}

interface IProps {
    match: any;
}

const Affiliate: React.FC<IProps> = ({ match }) => {
    const [redirectToWithdrawalRequest, setRedirectToWithdrawalRequest] = React.useState(false);
    const [redirectToAdvertiser, setRedirectToAdvertiser] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState("name");
    const [selectedRequest, setSelectedRequest] = React.useState("");
    const [selectedAdvertiser, setSelectedAdvertiser] = React.useState("");
    const dispatch = useDispatch();
    const withdrawalRequestListresult = useSelector((state: any) => {
        return state.affiliateReducer.list_result;
    });
    const wrListLoading = useSelector((state: any) => {
        return state.affiliateReducer.list_loading;
    });
    const searchAdvResult = useSelector((state: any) => {
        return state.affiliateReducer.search_adv_result;
    });
    const searchAdvLoading = useSelector((state: any) => {
        return state.affiliateReducer.search_adv_loading;
    });

    React.useEffect(() => {
        WithdrawalRequestListAction();
    }, []);

    const searchAdvertiserAction = () => {
        dispatch(actions.getSearchAdvertiser({
            keyword: searchKey,
            type: selectedOption
        }));
    };

    const WithdrawalRequestListAction = () => {
        // alert(searchKey);
        dispatch(actions.getWithdrawalRequestList({
            advertiser_id: "all",
            status: "p"
        }));
    };

    const searchKeywordHandler = (event: any) => {
        setSearchKey(event.target.value);
    };

    const selectOptionsSearch = (event) => {
        setSelectedOption(event.target.value);
    };

    const redirectWithdrawalRequestAction = (aw_id) => {
        setSelectedRequest(aw_id);
        setRedirectToWithdrawalRequest(true);
    };

    const redirectAdvertiserAction = (user_id) => {
        setSelectedAdvertiser(user_id);
        setRedirectToAdvertiser(true);
    };

    return (
        <Container fluid={true} className="container_div">
            {redirectToWithdrawalRequest && <Navigate to={"/withdrawal-request/" + selectedRequest} />}
            {redirectToAdvertiser && <Navigate to={"/advertiser/" + selectedAdvertiser} />}
            <Row>
                <Col>
                    {/* {JSON.stringify(searchAdvResult)} */}
                    <h1>Affiliate</h1>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Search Advertiser</h3>
                </Col>
            </Row>
            <Row>
                <Col xs="6" md="4">
                    <Input type="select" name="select" id="exampleSelect" onChange={selectOptionsSearch}>
                        <option value="name">Advertiser Name</option>
                        <option value="email">Advertiser Email</option>
                        <option value="user_id">User ID</option>
                    </Input>
                </Col>
                <Col xs="6" md="4">
                    <FormGroup>
                        <Input
                            type="text"
                            name="search_book"
                            id="searchBook"
                            placeholder="Keyword"
                            value={searchKey}
                            onChange={searchKeywordHandler}
                        />
                    </FormGroup>
                </Col>
                <Col xs="5" md="4">
                    <Button style={{ marginTop: 0 }} color="primary" block onClick={searchAdvertiserAction}>
                        Search
            </Button>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    {searchAdvLoading === true && (
                        <Row>
                            <Col>
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                    )}
                    {searchAdvLoading === false && searchAdvResult.length > 0 && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        USER ID
                                    </th>
                                    <th>
                                        NAME
                                    </th>
                                    <th>
                                        EMAIL
                                    </th>
                                    <th>
                                        DETAIL
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchAdvResult.map((rowData: any, index) => {
                                    return (
                                        <tr key={rowData.user_id + "_row"}>
                                            <td>{rowData.user_id}</td>
                                            <td>{rowData.user_name}</td>
                                            <td>{rowData.user_email}</td>
                                            <td style={{ cursor: "pointer" }} onClick={() => redirectAdvertiserAction(rowData.user_id)}><MdArrowForward /></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Col>

            </Row>

            <Row>
                <Col xs="12"><hr /></Col>
                <Col>
                    <h3>Pending Withdrawal Request</h3>
                </Col>

                <Col xs="4">
                    <Button style={{ marginTop: 0 }} color="primary" block onClick={WithdrawalRequestListAction}><MdRefresh /> List</Button>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    {wrListLoading === true && (
                        <Row>
                            <Col>
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                    )}
                    {wrListLoading === false && withdrawalRequestListresult.length > 0 && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        REQUEST ID
                                    </th>
                                    <th>
                                        REQESTED DATE
                                    </th>
                                    <th>
                                        STATUS
                                    </th>
                                    <th>
                                        PROGRAM ID
                                    </th>
                                    <th>
                                        ADVERTISER ID
                                    </th>
                                    <th>
                                        DETAIL
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdrawalRequestListresult.map((rowData: any, index) => {
                                    return (
                                        <tr key={rowData.aw_id + "_row"}>
                                            <td>{rowData.aw_id}</td>
                                            <td>{dateFormat(new Date(rowData.aw_creation_time), "yyyy-mm-dd")}</td>
                                            <td>{rowData.aw_status}</td>
                                            <td>{rowData.ap_id}</td>
                                            <td>{rowData.advertiser_id}</td>
                                            <td style={{ cursor: "pointer" }} onClick={() => redirectWithdrawalRequestAction(rowData.aw_id)}><MdArrowForward /></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Col>

            </Row>

        </Container >
    );
};

export default Affiliate;
