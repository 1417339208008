import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";

import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./addNewBook.css";

import Paper from "@mui/material/Paper";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AddBookBulk from "../../components/sub/addBookBulk/addBookBulk";
import AddBookSingle from "../../components/sub/addBookSingle/addBookSingle";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";

interface IProps {
  match: any;
}

const AddNewBook: React.FC<IProps> = (props: any) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const postBookDataResult = useSelector((state: any) => {
    return state.bookReducer.result;
  });
  const customUpdateResult = useSelector((state: any) => {
    return state.bookReducer.updateBook;
  });
  const loadingState = useSelector((state: any) => {
    return state.bookReducer.loading;
  });

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setSelectedTabIndex(newValue);
  }

  const dataSyncHandler = (bookData?: any) => {
    dispatch(actions.postBookData(bookData));
    // return true;
  };

  const customUpdateResultHandler = (bookData?: any) => {
    dispatch(actions.postCustomBookUpdate(bookData));
    // return true;
  };

  const updateCustomBookHandler = (data: any) => {
    dispatch(actions.postCustomBookUpdate(data));
  };

  return (
    <Container fluid={true} className="container_div">
      {loadingState && <LoadingModal showModal={true} />}
      <Row>
        <Col>
          <h1>Add New Book</h1>
        </Col>
      </Row>

      <Row style={{ marginBottom: 30 }}>
        <Col>
          <Paper square>
            <Tabs value={selectedTabIndex} indicatorColor="primary" textColor="primary" onChange={handleChange}>
              <Tab label="Single Book" />
              <Tab label="Upload Bulk" />
            </Tabs>
          </Paper>
        </Col>
      </Row>
      {selectedTabIndex === 0 &&
        <AddBookSingle postBookDataResult={postBookDataResult} dataSyncHandler={dataSyncHandler} />
      }
      {selectedTabIndex === 1 && (
        <AddBookBulk
          customUpdateResult={customUpdateResult}
          customUpdateResultHandler={customUpdateResultHandler}
          postBookDataResult={postBookDataResult}
          dataSyncHandler={dataSyncHandler}
        />
      )}
    </Container>
  );
};

export default AddNewBook;
