import * as actionTypes from "../../actions/actionType";

const initialState = {
    error: {},
    orderLoading: false,
    orderList: {}
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.GET_ORDER_INIT:
            return { ...state, orderLoading: true, error: "" };
        case actionTypes.GET_ORDER_FAILURE:
            return { ...state, orderLoading: false, error: action.payload };
        case actionTypes.GET_ORDER_SUCCESS:
            return { ...state, orderLoading: false, orderList: action.payload };
        case actionTypes.CLEAR_ORDER:
            return { ...state, orderLoading: false, orderList: [] };
        default:
            return state;
    }
};

export default reducer;
