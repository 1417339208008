import * as React from "react";
import "./voucher.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";
import * as actions from "../../store/actions/index";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";
import MessageModal from "../../components/ui/messageModal/messageModal";
import { Navigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";

import { Field, Form, Formik, FormikProps, ErrorMessage, } from "formik";

interface IProps {
    match: any;
}

const initialValue = {

}

const Voucher: React.FC<IProps> = ({ match }) => {

    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [startDate, setStartDate] = React.useState(new Date());
    const [voucherListResult, setVoucherListResult] = React.useState(new Array());

    const dispatch = useDispatch();
    const pageLoading = useSelector((state: any) => {
        return state.voucherReducer.loading_loading;
    });
    const voucherListRawResult = useSelector((state: any) => {
        return state.voucherReducer.get_voucher_result;
    });
    const postedVoucherResult = useSelector((state: any) => {
        return state.voucherReducer.post_voucher_result;
    });
    const postedVoucherStatusResult = useSelector((state: any) => {
        return state.voucherReducer.post_voucher_st_result;
    });

    const getVoucherAPI = () => {
        dispatch(
            actions.getVoucher({
                page_number: 0,
                page_size: 100
            })
        );
    }

    React.useEffect(() => {
        getVoucherAPI();
    }, []);

    React.useEffect(() => {
        if (Object.keys(voucherListRawResult).length != 0) {
            setVoucherListResult([...voucherListRawResult]);
        }
    }, [voucherListRawResult]);

    React.useEffect(() => {
        if (Object.keys(postedVoucherResult).length != 0) {
            setMessage(postedVoucherResult);
            setShowMessage(true);
            getVoucherAPI();
        }
    }, [postedVoucherResult]);

    React.useEffect(() => {
        if (Object.keys(postedVoucherStatusResult).length != 0) {
            let tempItems = [...voucherListRawResult];
            for (let i = 0; i < tempItems.length; i++) {
                if (tempItems[i].p_m_id === postedVoucherStatusResult.p_m_id) {
                    tempItems[i].p_m_is_active = postedVoucherStatusResult.p_m_is_active;
                    break;
                }
            }
            setVoucherListResult([...tempItems]);
        }
    }, [postedVoucherStatusResult]);

    const handleAddVoucherSubmit = (values, actions) => {
        submitVoucherAction(values);
    };

    const closeModalHandler = () => {
        setMessage("");
        setShowMessage(false);
    };

    const submitVoucherAction = (values) => {

        dispatch(
            actions.postVoucher({
                ...values,
                p_m_end_time: values.p_m_end_time + ' 23:59:59',
                p_m_start_time: values.p_m_start_time + ' 00:00:01'
            })
        );
    }

    const updateVoucherStatusAction = (voucher) => {
        let data = {
            p_m_id: voucher.p_m_id,
            p_m_is_active: (voucher.p_m_is_active === 1 ? 0 : 1)
        };
        dispatch(
            actions.postVoucherStatus(data)
        );
    }



    return (
        <Container fluid={true} className="container_div">
            <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
            {pageLoading && <LoadingModal showModal={true} />}
            <Row>
                <Col>
                    <h1>Voucher</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Add New Voucher</h2>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Formik
                        initialValues={initialValue}
                        validate={(values: any) => {
                            let errors: any = {};
                            // partner_id, p_m_is_redeemable, p_m_start_time, p_m_end_time, p_m_desc, p_m_title, p_m_amount, 
                            // p_m_min_no_book, p_m_min_amount, p_m_code, p_m_total_no_voucher, p_m_redeem_type,p_m_redeem_pay,p_m_user_unique,p_m_user_time_use
                            if (!values.p_m_is_redeemable) { errors.p_m_is_redeemable = "It is Required"; }
                            if (!values.p_m_desc) { errors.p_m_desc = "It is Required"; }
                            if (!values.p_m_title) { errors.p_m_title = "It is Required"; }
                            if (!values.p_m_amount) { errors.p_m_amount = "It is Required"; }
                            if (!values.p_m_min_no_book) { errors.p_m_min_no_book = "It is Required"; }
                            if (!values.p_m_min_amount) { errors.p_m_min_amount = "It is Required"; }
                            if (!values.p_m_code) { errors.p_m_code = "It is Required"; }
                            if (!values.p_m_total_no_voucher) { errors.p_m_total_no_voucher = "It is Required"; }
                            if (!values.p_m_redeem_type) { errors.p_m_redeem_type = "It is Required"; }
                            if (!values.p_m_redeem_pay) { errors.p_m_redeem_pay = "It is Required"; }
                            if (!values.p_m_user_unique) { errors.p_m_user_unique = "It is Required"; }
                            if (!values.p_m_user_time_use) { errors.p_m_user_time_use = "It is Required"; }

                            return errors;
                        }}


                        onSubmit={handleAddVoucherSubmit}
                    >
                        {(formProps) => (
                            <Form>
                                <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
                                <Row className="seprated_row">
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Is it Redeemable?</Label>
                                        <Field name="p_m_is_redeemable" component="select">
                                            <option value="">--</option>
                                            <option value="1">YES</option>
                                            <option value="0">NO</option>
                                        </Field>
                                        <ErrorMessage name="p_m_is_redeemable" className="" />
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Start Date</Label>
                                        <Field type="text" name="p_m_start_time" placeholder="Start Date: yyyy-mm-dd" />
                                        <ErrorMessage name="p_m_start_time">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>End Date</Label>
                                        <Field type="text" name="p_m_end_time" placeholder="End Date: yyyy-mm-dd" />
                                        <ErrorMessage name="p_m_end_time">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Title (200 char)</Label>
                                        <Field type="text" name="p_m_title" />
                                        <ErrorMessage name="p_m_title">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Description (1000 char)</Label>
                                        <Field type="text" name="p_m_desc" />
                                        <ErrorMessage name="p_m_desc">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Amount</Label>
                                        <Field type="text" name="p_m_amount" />
                                        <ErrorMessage name="p_m_amount">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Min. No. of Book</Label>
                                        <Field type="text" name="p_m_min_no_book" />
                                        <ErrorMessage name="p_m_min_no_book">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Min. Order Amount</Label>
                                        <Field type="text" name="p_m_min_amount" />
                                        <ErrorMessage name="p_m_min_amount">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Promo Code</Label>
                                        <Field type="text" name="p_m_code" />
                                        <ErrorMessage name="p_m_code">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Max. No. of Voucher</Label>
                                        <Field type="text" name="p_m_total_no_voucher" />
                                        <ErrorMessage name="p_m_total_no_voucher">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>User Redemption Type</Label>
                                        <Field name="p_m_redeem_type" component="select">
                                            <option value="">--</option>
                                            <option value="0">Instant Redemption</option>
                                            <option value="1">Green Points</option>
                                            {/* <option value="2">Cash Points</option> */}
                                        </Field>
                                        <ErrorMessage name="p_m_redeem_type" className="" />
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>User Redemption Value</Label>
                                        <Field type="text" name="p_m_redeem_pay" />
                                        <ErrorMessage name="p_m_redeem_pay" className="" />
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>Unique to One User?</Label>
                                        <Field name="p_m_user_unique" component="select">
                                            <option value="">--</option>
                                            <option value="0">NO</option>
                                            <option value="1">YES</option>
                                        </Field>
                                        <ErrorMessage name="p_m_user_unique" className="" />
                                    </Col>
                                    <Col xs="12" md="3" className="custom_formik_col">
                                        <Label>User No. of Use?</Label>
                                        <Field name="p_m_user_time_use" component="select">
                                            <option value="">--</option>
                                            <option value="0">Unlimited</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                            <option value="30">40</option>
                                            <option value="30">50</option>
                                        </Field>
                                        <ErrorMessage name="p_m_user_time_use" className="" />
                                    </Col>
                                    <Col xs="12" className="custom_formik_col">
                                        <button type="submit">Submit Form</button>
                                    </Col>
                                </Row>


                            </Form>

                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>List of Vouchers</h2>
                    <hr />

                    {voucherListResult.length > 0 && (
                        <Table className="no-padding_cell">
                            <thead>
                                <tr>
                                    <th>#ID</th>
                                    <th>Redem?</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Title</th>
                                    <th>Desc.</th>
                                    <th>Amount</th>
                                    <th>Min. Book</th>
                                    <th>Min. Amt.</th>
                                    <th>Code</th>
                                    <th>Max. No.</th>
                                    <th>Redemp. Type</th>
                                    <th>Redemp. Points</th>
                                    <th>Unique?</th>
                                    <th>UserU.</th>
                                    <th>st.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {voucherListResult.map((rowData: any, index) => {
                                    return (
                                        <tr key={rowData.p_m_id + "_row"}>
                                            <td>{rowData.p_m_id}</td>
                                            <td>{rowData.p_m_is_redeemable === 1 ? "YES" : "NO"}</td>
                                            <td>{dateFormat(new Date(rowData.p_m_start_time), "yyyy-mm-dd")}</td>
                                            <td>{dateFormat(new Date(rowData.p_m_end_time), "yyyy-mm-dd")}</td>
                                            <td>{rowData.p_m_title}</td>
                                            <td>{rowData.p_m_desc}</td>
                                            <td>{rowData.p_m_amount}</td>
                                            <td>{rowData.p_m_min_no_book}</td>
                                            <td>{rowData.p_m_min_amount}</td>
                                            <td>{rowData.p_m_code}</td>
                                            <td>{rowData.p_m_total_no_voucher}</td>
                                            <td>{rowData.p_m_redeem_type === 0 ? "Instant" : "Green"}</td>
                                            <td>{rowData.p_m_redeem_pay}</td>
                                            <td>{rowData.p_m_user_unique === 1 ? "YES" : "NO"}</td>
                                            <td>{rowData.p_m_user_time_use}</td>
                                            <td>
                                                <Button color={rowData.p_m_is_active === 1 ? "danger" : "success"} onClick={() => { updateVoucherStatusAction(rowData) }}>
                                                    {rowData.p_m_is_active === 1 ? "OFF" : "ON"}
                                                </Button>
                                            </td>
                                            {/* <td style={{ cursor: "pointer" }} onClick={() => redirectAdvertiserAction(rowData.user_id)}><MdArrowForward /></td> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Voucher;
