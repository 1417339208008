import * as React from "react";

import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";

interface IProps {
  showModal: boolean;
  message: string;
  closeModalHandler: any;
}

const MessageModal: React.FC<IProps> = (props: any) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  return (
    <React.Fragment>
      <Modal isOpen={props.showModal}>
        <ModalHeader>Message</ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>{props.message}</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.closeModalHandler}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default MessageModal;
