// import * as dotenv from 'dotenv';
// dotenv.config();
// import {Buffer} from "buffer";
// window.Buffer = window.Buffer || require("buffer").Buffer;

// import EncryptRsa from "encrypt-rsa";
import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";

const envVar = process.env.REACT_APP_NODE_ENV;
const publicKeyBase64URL = process.env.REACT_APP_PUBLIC_KEY;
const DOLLAR_RATE = 4.73;
const YUAN_RATE = 0.7;


export default class GlobalFunction {


  public static getSecretKey() {
    return "DwyajTyMiM409WuRZsOCDKXRgc9qZ7ke";
  }

  public getBookPriceRM(bookPrice: number, bookCurrency: string) {
    var priceObj = bookPrice;

    if (bookCurrency == "usd") {
      priceObj = priceObj * DOLLAR_RATE;
    } else if (bookCurrency == "cny") {
      priceObj = priceObj * YUAN_RATE;
    }

    return priceObj;
  }

  public getServerPortBaseURL() {

    if (envVar === "development") {
      return "http://localhost:3000/";
      // return "https://api.bookurve.com/";      
    }
    else {
      return "https://api.bookurve.com/";
    }
  }

  public encryptPassPhrase(text: string) {
    try {
      if (publicKeyBase64URL) {
        // const publicKey = Buffer.from(publicKeyBase64URL, 'base64').toString();
        const publicKey = window.atob(publicKeyBase64URL);
        // const encryptRsa = new EncryptRsa();
        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        let encryptedTxt = encrypt.encrypt(text);
        // const encryptedTxt = encryptRsa.encryptStringWithRsaPublicKey({
        //   text,
        //   publicKey,
        // });
        return encryptedTxt;
      }
    } catch (e) {
      console.error(e);
    }
  }

  public getHashOfString(text: string) {
    try {
      if (text) {
        const hashed = CryptoJS.SHA256(text).toString();
        return hashed;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
