import * as React from "react";
// import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import * as actions from "../../store/actions/index";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";
import MessageModal from "../../components/ui/messageModal/messageModal";
import { Navigate } from "react-router-dom";



// import { Field, Form, Formik, FormikProps } from "formik";

interface IUrlObj {
    bookISBN: string;
}

interface IProps {
    match: any;
}

const User: React.FC<IProps> = ({ match }) => {
    const [userID, setUserID] = React.useState("");
    const [userEmail, setUserEmail] = React.useState("");
    const [userPassword, setUserPassword] = React.useState("");
    const [cashPoints, setCashPoints] = React.useState(0);
    const [greenPoints, setGreenPoints] = React.useState(0);
    const [showMessage, setShowMessage] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [redirectToTradein, setRedirectToTradein] = React.useState(false);

    const dispatch = useDispatch();
    const userSearchResult = useSelector((state: any) => {
        return state.userReducer.result;
    });
    const searchLoading = useSelector((state: any) => {
        return state.userReducer.user_loading;
    });
    const updateLoading = useSelector((state: any) => {
        return state.userReducer.update_loading;
    });
    const userInfoResult = useSelector((state: any) => {
        return state.userReducer.userinfo_result;
    });
    const userPassResult = useSelector((state: any) => {
        return state.userReducer.userpass_result;
    });
    const userIDHandler = (event: any) => {
        setUserID(event.target.value);
    };
    const userEmailHandler = (event: any) => {
        setUserEmail(event.target.value);
    };
    const userPasswordHandler = (event: any) => {
        setUserPassword(event.target.value);
    };
    const cashPointsHandler = (event: any) => {
        setCashPoints(event.target.value);
    };
    const greenPointsHandler = (event: any) => {
        setGreenPoints(event.target.value);
    };
    const userSearchAction = () => {
        if (userID.length > 0) {
            let data = {
                type: "user_id",
                key: userID
            }
            dispatch(
                actions.getUserSearch(data)
            );
        }
        else if (userEmail.length > 0) {
            let data = {
                type: "user_email",
                key: userEmail
            }
            dispatch(
                actions.getUserSearch(data)
            );
        }
    }
    const saveUserInfoAction = () => {
        let data = {
            user_points: cashPoints,
            user_green_points: greenPoints,
            user_id: userSearchResult.user_id
        };
        dispatch(
            actions.updateUserInfo(data)
        );
    }
    const saveUserPasswordAction = () => {
        let data = {
            user_password: userPassword,
            user_id: userSearchResult.user_id
        };
        dispatch(
            actions.updateUserPassword(data)
        );
    }
    React.useEffect(() => {
        if (userSearchResult) {
            setCashPoints(userSearchResult.user_points);
            setGreenPoints(userSearchResult.user_green_points);
        }
    }, [userSearchResult]);
    React.useEffect(() => {
        if (userInfoResult && userInfoResult.show_message && userInfoResult.show_message === "yes") {
            setMessage(userInfoResult.message_text);
            setShowMessage(true);
        }
    }, [userInfoResult]);
    React.useEffect(() => {
        if (userPassResult && userPassResult.show_message && userPassResult.show_message === "yes") {
            setMessage(userPassResult.message_text);
            setShowMessage(true);
        }
    }, [userPassResult]);

    const closeModalHandler = () => {
        setMessage("");
        setShowMessage(false);
    };

    const createTradeinBatchAction = () => {
        // window.location.href = "/admin/batch-details/" + userSearchResult.user_id;
        setRedirectToTradein(true);
    }

    return (
        <Container fluid={true} className="container_div">
             {redirectToTradein && <Navigate to={"/batch-details/" + userSearchResult.user_id} />}
            <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
            {updateLoading && <LoadingModal showModal={true} />}
            <Row>
                <Col>
                    <h1>User</h1>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col xs="6" md="4">
                        <FormGroup>
                            <Label for="exampleEmail">User ID</Label>
                            <Input
                                type="text"
                                name="user_id_txt"
                                id="userId_id"
                                placeholder="User ID"
                                value={userID}
                                onChange={userIDHandler}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6" md="4">
                        <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input
                                type="text"
                                name="user_email_txt"
                                id="userEmail_id"
                                placeholder="User Email"
                                value={userEmail}
                                onChange={userEmailHandler}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6" md="4">
                        <Button style={{ marginTop: 30 }} color="primary" block onClick={userSearchAction}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
            <hr />
            {searchLoading && (
                <Row className="no-padding">
                    <Col className="spinner_container"><Spinner color="dark" /></Col>
                </Row>
            )}
            {/* SHOW USER DETAILS */}
            {!searchLoading && userSearchResult && (
                <Form>
                    <Row>
                        <Col xs="6" md="4" style={{ paddingBottom: "20px", fontSize: "17px", fontWeight: 500, textTransform: "capitalize" }}>
                            User Name: {userSearchResult.user_name}
                        </Col>
                        <Col xs="6" md="4" style={{ paddingBottom: "20px", fontSize: "17px", fontWeight: 500, textTransform: "capitalize" }}>
                            Email: {userSearchResult.user_email}
                        </Col>
                        <Col xs="6" md="4" style={{ paddingBottom: "20px", fontSize: "17px", fontWeight: 500, textTransform: "capitalize" }}>
                            -
                        </Col>
                        <Col xs="6" md="4">
                            <FormGroup>
                                <Label for="exampleEmail">Cash Points</Label>
                                <Input
                                    type="text"
                                    name="user_point_txt"
                                    id="userPoint_id"
                                    placeholder="User Cash Point"
                                    value={cashPoints}
                                    onChange={cashPointsHandler}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="6" md="4">
                            <FormGroup>
                                <Label for="exampleEmail">Green Points</Label>
                                <Input
                                    type="text"
                                    name="user_green_point_txt"
                                    id="userGreenPoint_id"
                                    placeholder="User Green Point"
                                    value={greenPoints}
                                    onChange={greenPointsHandler}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="6" md="4">
                            <Button style={{ marginTop: 30 }} color="success" block onClick={saveUserInfoAction}>
                                Save
                        </Button>
                        </Col>
                    </Row>
                    <Row style={{ background: "#FFC300" }}>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="exampleEmail">User Password</Label>
                                <Input
                                    type="text"
                                    name="user_password_txt"
                                    id="userPassword_id"
                                    placeholder="User Password"
                                    value={userPassword}
                                    onChange={userPasswordHandler}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <Button style={{ marginTop: 30 }} color="danger" block onClick={saveUserPasswordAction}>
                                Reset Password
                        </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                            {/* <Navigate to={"/admin/batch-details/" + userSearchResult.user_id}> */}
                            {/*  <Link href={"" + userSearchResult.user_id}> */}
                            <div className="btn btn-info" style={{ marginTop: 30 }} onClick={createTradeinBatchAction}>
                                Create Tradein Batch
                                </div>
                            {/* </Redirect> */}
                        </Col>
                    </Row>
                </Form>
            )}
        </Container>

    );
};

export default User;
