import React from "react";
import "./App.css";
import { CookiesProvider } from "react-cookie";


import Router from "./routes";

const App: React.FC = () => {
  return (
    <CookiesProvider>
      <React.StrictMode>
        <div className="App">
          <Router />
        </div>
      </React.StrictMode>
    </CookiesProvider>
  );
};

export default App;
