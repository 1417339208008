import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getBundleListInit = () => {
  return {
    type: actionType.GET_BUNDLE_LIST_INIT
  };
};

const getBundleListFailure = (err: any) => {
  return {
    type: actionType.GET_BUNDLE_LIST_FAILURE,
    payload: err
  };
};

const getBundleListSuccess = (result: any) => {
  return {
    type: actionType.GET_BUNDLE_LIST_SUCCESS,
    payload: result
  };
};

const postBundleInit = () => {
  return {
    type: actionType.POST_BUNDLE_INIT
  };
};

const postBundleFailure = (err: any) => {
  return {
    type: actionType.POST_BUNDLE_FAILURE,
    payload: err
  };
};

const postBundleSuccess = (result: any) => {
  return {
    type: actionType.POST_BUNDLE_SUCCESS,
    payload: result
  };
};

const deleteBundleInit = () => {
  return {
    type: actionType.DELETE_BUNDLE_INIT
  };
};

const deleteBundleFailure = (err: any) => {
  return {
    type: actionType.DELETE_BUNDLE_FAILURE,
    payload: err
  };
};

const deleteBundleSuccess = (result: any) => {
  return {
    type: actionType.DELETE_BUNDLE_SUCCESS,
    payload: result
  };
};

export const getBundleList = (): any => {
  return (dispatch: any) => {
    dispatch(getBundleListInit());

    bookAPI
      .getBundleList()
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBundleListSuccess(result.data));
        } else {
          dispatch(getBundleListFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBundleListFailure(err));
      });
  };
};

export const postBundle = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(postBundleInit());

    bookAPI
      .postBundle(postData)
      .then((result: any) => {
        if (result && result.status === "success") {
          dispatch(postBundleSuccess(result.data));
        } else {
          dispatch(postBundleFailure("Server Error!"));
        }
      })
      .catch((err: any) => {
        dispatch(postBundleFailure("Server Error!"));
      });
  };
};

export const deleteBundle = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(deleteBundleInit());

    bookAPI
      .deleteBundle(postData)
      .then((result: any) => {
        if (result && result.status === "success") {
          dispatch(deleteBundleSuccess(result.data));
        } else {
          dispatch(deleteBundleFailure("Server Error!"));
        }
      })
      .catch((err: any) => {
        dispatch(deleteBundleFailure("Server Error!"));
      });
  };
};
