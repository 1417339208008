import * as actionTypes from "../../actions/actionType";

const initialState = {
  error: {},
  delete_loading: false,
  delete_result: {},
  msg: ""
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.DELETE_CACHE_INIT:
      return { ...state, delete_loading: true, error: "", msg: "" };
    case actionTypes.DELETE_CACHE_FAILURE:
      return { ...state, delete_loading: false, error: action.payload, msg: "ERROR!" };
    case actionTypes.DELETE_CACHE_SUCCESS:
      return { ...state, delete_loading: false, delete_result: action.payload, msg: "DONE :)" };
    case actionTypes.CLEAR_CACHE_MSG:
      return { ...state, delete_loading: false, msg: "" };
    default:
      return state;
  }
};

export default reducer;
