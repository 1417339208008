import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getUserInit = () => {
    return {
        type: actionType.GET_USER_INIT
    };
};

const getUserFailure = (err: any) => {
    return {
        type: actionType.GET_USER_FAILURE,
        payload: err
    };
};

const getUserSuccess = (result: any) => {
    return {
        type: actionType.GET_USER_SUCCESS,
        payload: result
    };
};

const updateUserInfoInit = () => {
    return {
        type: actionType.UPDATE_USER_INFO_INIT
    };
};

const updateUserInfoFailure = (err: any) => {
    return {
        type: actionType.UPDATE_USER_INFO_FAILURE,
        payload: err
    };
};

const updateUserInfoSuccess = (result: any) => {
    return {
        type: actionType.UPDATE_USER_INFO_SUCCESS,
        payload: result
    };
};

const updateUserPasswordInit = () => {
    return {
        type: actionType.UPDATE_USER_PASS_INIT
    };
};

const updateUserPasswordFailure = (err: any) => {
    return {
        type: actionType.UPDATE_USER_PASS_FAILURE,
        payload: err
    };
};

const updateUserPasswordSuccess = (result: any) => {
    return {
        type: actionType.UPDATE_USER_PASS_SUCCESS,
        payload: result
    };
};

export const getUserSearch = (data): any => {
    return (dispatch: any) => {
        dispatch(getUserInit());

        bookAPI
            .getSearchUserInfo(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(getUserSuccess(result.data));
                } else {
                    dispatch(getUserFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(getUserFailure(err));
            });
    };
};

export const updateUserInfo = (data): any => {
    return (dispatch: any) => {
        dispatch(updateUserInfoInit());

        bookAPI
            .updateUserInfo(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(updateUserInfoSuccess(result.data));
                } else {
                    dispatch(updateUserInfoFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(updateUserInfoFailure(err));
            });
    };
};

export const updateUserPassword = (data): any => {
    return (dispatch: any) => {
        dispatch(updateUserPasswordInit());

        bookAPI
            .UpdateUserPassword(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(updateUserPasswordSuccess(result.data));
                } else {
                    dispatch(updateUserPasswordFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(updateUserPasswordFailure(err));
            });
    };
};