import axios from "axios";
import GlobalFunction from "../util/globalFunctions";

export default class BookApi {
  public gf = new GlobalFunction();

  public state = {
    baseURL: ""
  };

  constructor() {
    // this.state.baseURL = this.gf.getServerPortBaseURL() + 'book/';
    this.state.baseURL = this.gf.getServerPortBaseURL();
    axios.defaults.withCredentials = true;
  }

  public getBestSellerBooks() {
    return axios
      .get(this.state.baseURL)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }
  public getExternalOrders(data) {
    return axios
      .get(`${this.state.baseURL}cms/order/sync-external-orders/${data.page_number}/${data.page_size}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public getOrders(data) {
    return axios
      .get(`${this.state.baseURL}cms/order/list/${data.type}/${data.keyword}/${data.page_number}/${data.page_size}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public postUserSecurePhrase(data) {
    return axios
      .post(this.state.baseURL + "cms/login/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        // alert(error);
        throw error;
      });
  }

  public checkIsUserLogedIn() {
    return axios
      .post(this.state.baseURL + "cms/check-login/")
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        // alert(error);
        throw error;
      });
  }

  public logout() {
    return axios
      .post(this.state.baseURL + "cms/logout/")
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        // alert(error);
        throw error;
      });
  }

  public postAddNewBook(data) {
    return axios
      .post(this.state.baseURL + "cms/add_new_book/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public postCustomBookUpdate(data) {
    return axios
      .post(this.state.baseURL + "cms/update_custom_book/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public updateBookDetails(data) {
    return axios
      .post(this.state.baseURL + `cms/book/update/${data.book_isbn}/`, data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }
  public postBookFlags(data) {

    const url = `cms/selected/${data.book_isbn}/${data.book_language}/${data.is_activated}/${data.flag}/${data.order_no}/`;
    return axios
      .post(this.state.baseURL + url, data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public postLightSearch(data) {

    let url = `cms/book/light-search/add/${data.book_isbn}/${data.book_language}/`;
    if (data.is_delete) {
      url = `cms/book/light-search/remove/${data.book_isbn}/${data.book_language}/`;
    }
    return axios
      .post(this.state.baseURL + url, data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public getBookFlags(data) {
    return axios
      .get(this.state.baseURL + `cms/selected/${data.book_isbn}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public searchBookByIsbn(data) {
    return axios
      .get(this.state.baseURL + `cms/book/${data.book_isbn}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public getBookCategoryList() {
    return axios
      .get(this.state.baseURL + `cms/book/category-list/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw error;
      });
  }

  public getBundleList() {
    return axios
      .get(this.state.baseURL + "cms/bundle_list/0/100/")
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postBundle(data) {
    return axios
      .post(this.state.baseURL + "cms/bundle/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public deleteBundle(data) {
    return axios
      .post(this.state.baseURL + "cms/delete-bundle/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getSaleList() {
    return axios
      .get(this.state.baseURL + "cms/sale/500/")
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postSale(data) {
    return axios
      .post(this.state.baseURL + "cms/sale/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public deleteSale(data) {
    return axios
      .post(this.state.baseURL + "cms/delete-sale/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public deleteCache(data) {
    return axios
      .post(this.state.baseURL + "cms/delete-cache/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getSearchUserInfo(data) {
    return axios
      .get(this.state.baseURL + `cms/user/${data.type}/${data.key}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public updateUserInfo(data) {
    return axios
      .post(this.state.baseURL + "cms/update_user/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public UpdateUserPassword(data) {
    return axios
      .post(this.state.baseURL + "cms/update_user_password/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getBatchId(data) {
    let query = "";
    if (data.batch_id) {
      query = `cms/tradein/get-new-batch-id/${data.user_id}/${data.batch_id}/`;
    }
    else {
      query = `cms/tradein/get-new-batch-id/${data.user_id}/`;
    }
    return axios
      .get(this.state.baseURL + query)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getSearchISBNorUID(data) {
    return axios
      .get(this.state.baseURL + `cms/tradein/search-isbn-uid/${data.type}/${data.key}/${data.second_key}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getBatchItems(data) {
    return axios
      .get(this.state.baseURL + `cms/tradein/batch-item/${data.batch_id}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public saveBatchItem(data) {
    return axios
      .post(this.state.baseURL + "cms/tradein/save-batch-item/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public deleteBatchItem(data) {
    return axios
      .post(this.state.baseURL + "cms/tradein/delete-batch-item/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getBatchList(data) {
    return axios
      .get(this.state.baseURL + `cms/tradein/batch/${data.user_id}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postBatchConfirmStatus(data) {
    return axios
      .post(this.state.baseURL + "cms/tradein/update-batch-complete-status/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getWithdrawalRequestList(data) {
    return axios
      .get(this.state.baseURL + `cms/withdrawal/${data.advertiser_id}/${data.status}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getWithdrawalRequestDetails(data) {
    return axios
      .get(this.state.baseURL + `cms/withdrawal-details/${data.request_id}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getSearchAdvertiser(data) {
    return axios
      .get(this.state.baseURL + `cms/withdrawal/search-advertiser/${data.keyword}/${data.type}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postWithdrawalPaymentCreate(data) {
    return axios
      .post(this.state.baseURL + "cms/withdrawal-payment/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getAffiliateOrdersForPayment(data) {
    return axios
      .get(this.state.baseURL + `cms/affiliate-order/${data.payment_id}/`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postPaymentDone(data) {
    return axios
      .post(this.state.baseURL + "cms/payment-done/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getAdvertiserDetails(data) {
    const url = this.state.baseURL + `cms/affiliate_advertiser/${data.advertiser_id}/`;
    return axios
      .get(url)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public getVoucher(data) {
    const url = this.state.baseURL + `cms/voucher/${data.page_number}/${data.page_size}/`;
    return axios
      .get(url)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postVoucher(data) {
    return axios
      .post(this.state.baseURL + "cms/voucher/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }

  public postVoucherStatus(data) {
    return axios
      .post(this.state.baseURL + "cms/voucher-activate-status/", data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return false;
      });
  }
}
