import * as React from "react";
import "./updateBookDetail.css";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, InputLabel, MenuItem, OutlinedInput, Checkbox } from '@mui/material';
import { ListItemText, FormControl, TextField } from '@mui/material/';
import { ButtonGroup, Chip, Stack, FormControlLabel } from '@mui/material/';
import { useFormik } from 'formik';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import MessageModal from "../../ui/messageModal/messageModal";
import GlobalFunction from "../../../util/globalFunctions";


interface BookInterface {
  book_isbn: string;
  book_title: string;
  book_image: string;
  book_author: string;
}

interface categoryInterface {
  cat_name: string;
  cat_code: string;
}

type selectOptionType = {
  value: string;
  label: string;
};

const languageOptions: selectOptionType[] = [
  { value: "", label: "--Select--" },
  { value: "en", label: "English" },
  { value: "cn", label: "Chinese" },
  { value: "bn", label: "Malay" }
];


type Props = {
  updateDataHandler: any;
  updateDataResult: any;
  data: BookInterface;
  englishCategories: any;
  chineseCategories: any;
  malayCategories: any;
  bookSellers: any;
  childrenCategories: any;
  bookFormats: any;
  stockStatuses: any;
  bookFlags: any;
  bookFlagsLoading: any;
  postBookFlagHandler: any;
  postLightSearchHandler: any;
  postLightSearchLoading: any;
};

const UpdateBookDetail: React.FC<Props> = (props: any) => {
  const glob = new GlobalFunction();

  const [showMessage, setShowMessage] = React.useState(false);
  const [isForRent, setIsForRent] = React.useState(false);
  const [isFlagTrendy, setIsFlagTrendy] = React.useState(false);
  const [flagBestsellerNo, setFlagBestsellerNo] = React.useState(0);
  const [isFlagBestseller, setIsFlagBestseller] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [bookStock, setBookStock] = React.useState<number>(0);
  const [selectedStock, setSelectedStock] = React.useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(languageOptions[0].value);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);
  const [selectedCategoryTitles, setSelectedCategoryTitles] = React.useState<string[]>([]);
  const [languageCategories, setLanguageCategories] = React.useState<categoryInterface[]>([]);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [selectedPublishedDate, setSelectedPublishedDate] = React.useState<Dayjs | null>(dayjs(new Date()));


  const formik = useFormik({
    initialValues: props.data,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      values.book_weight = values.book_weight.toString();
      values.book_pub_date = selectedPublishedDate?.format('YYYY-MM-DD');
      values.is_rent = isForRent ? true : false;
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      values.book_synopsis = html;
      if (selectedCategories.length > 0) {
        values.book_category = `-${selectedCategories.join('-')}-`;
      }
      values.book_language = selectedLanguage;
      values.book_stock = Number(bookStock);
      props.updateDataHandler(values);
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const languageHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguage(value);

    const selectedOption = languageOptions.filter((language) => {
      if (language.value == value) {
        return language;
      }
    });
    if (selectedOption && selectedOption[0].value) {
      updateCategoryList(selectedOption[0].value);
    }
  };

  const categoryHandleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    let tempSelectedCatCode = ""
    if (Array.isArray(value)) {
      tempSelectedCatCode = value[value.length - 1].toString();
    }
    else {
      tempSelectedCatCode = value.toString();
    }
    languageCategories.forEach(cat => {
      if (cat.cat_code == tempSelectedCatCode) {
        const indexOfSelectedCat = selectedCategories.indexOf(tempSelectedCatCode);
        if (indexOfSelectedCat < 0) {//It is not in the list
          setSelectedCategories((prev) => [...prev, tempSelectedCatCode]);
          setSelectedCategoryTitles((prev) => [...prev, cat.cat_name]);
        }
        else {//Exist in the list
          var arrayCat = [...selectedCategories];
          arrayCat.splice(indexOfSelectedCat, 1);
          setSelectedCategories(arrayCat);

          var arrayCatTitle = [...selectedCategoryTitles];
          arrayCatTitle.splice(indexOfSelectedCat, 1);
          setSelectedCategoryTitles(arrayCatTitle);
        }

      }
    });

  };

  const renderCategoryListValue = (selected) => {
    return selected.join(', ');
  }

  const handleSubmit = (values, actions) => {
    values.book_category = "-" + values.book_category + "-";
    props.dataSyncHandler(values);
  };

  const closeModalHandler = () => {
    setMessage("");
    setShowMessage(false);
  };

  React.useEffect(() => {
    setIsFlagTrendy(false);
    setIsFlagBestseller(false);
    setFlagBestsellerNo(0);
    if (props.bookFlags) {
      props.bookFlags.forEach(flag => {
        if (flag.b_selected_flag == "trendy") {
          setIsFlagTrendy(true);
        }
        else if (flag.b_selected_flag == "bestseller") {
          setIsFlagBestseller(true);
          setFlagBestsellerNo(flag.b_order);
        }
      });
    }
  }, [props.bookFlags]);

  React.useEffect(() => {

    if (props.data && props.data.book_isbn) {

      setBookStock(props.data.book_stock);
      setIsForRent(props.data.is_rent);
      let selectedCode = "";
      if (props.data.book_stock > 0) {
        selectedCode = "0001";
      }
      else if (props.data.book_stock == 0) {
        selectedCode = "0002";
      }
      else {
        selectedCode = props.data.book_stock.toString();
      }
      setSelectedStock(selectedCode);

      languageOptions.map((language) => {
        if (language.value == props.data.book_language) {
          setSelectedLanguage(language.value);
        }
      });

      if (props.englishCategories && props.englishCategories.length > 0) {
        updateCategoryList(props.data.book_language);
      }

      setSelectedPublishedDate(dayjs(formik.values.book_pub_date));


      // const state = ContentState.createFromBlockArray(
      //   blocksFromHTML.contentBlocks,
      //   blocksFromHTML.entityMap,
      // );
      // setEditorState(EditorState.createWithContent(state));

      props.data.book_synopsis = props.data.book_synopsis.replaceAll('<figure', '<div');
      props.data.book_synopsis = props.data.book_synopsis.replaceAll('figure>', 'div>');

      const blocksFromHtml = htmlToDraft(props.data.book_synopsis);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
      formik.setFieldValue("book_image", props.data.book_image);
    }
  }, [props]);


  const updateCategoryList = (lan) => {

    setSelectedCategories([]);
    setSelectedCategoryTitles([]);

    let bookCatArray = props.data.book_category.split('-');
    bookCatArray = bookCatArray.filter((a) => (a && a.length > 0));

    let currentCatList;
    if (lan == "cn") {
      setLanguageCategories(props.chineseCategories);
      currentCatList = props.chineseCategories;
    }
    else if (lan == "bn") {
      setLanguageCategories(props.malayCategories);
      currentCatList = props.malayCategories;
    }
    else {
      setLanguageCategories(props.englishCategories);
      currentCatList = props.englishCategories;
    }

    currentCatList.forEach(cat => {
      if (bookCatArray.indexOf(cat.cat_code) >= 0) {
        setSelectedCategories((prev) => [...prev, cat.cat_code]);
        setSelectedCategoryTitles((prev) => [...prev, cat.cat_name]);
      }
    });
  }

  const stockOptionHandler = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStock(value);

    if (props.stockStatuses[0]?.code == value) {
      setBookStock(props.data.book_stock);
    }
    else if (props.stockStatuses[1]?.code == value) {
      setBookStock(0);
    }
    else {
      setBookStock(parseInt(value));
    }
  }

  const stockChangeHandler = (event) => {
    const {
      target: { value },
    } = event;
    setBookStock(value);
  }

  const rentCheckBoxHandler = () => {
    setIsForRent(isForRent ? false : true);
  }

  const bestsellerCheckBoxHandler = () => {
    const data = {
      book_isbn: props.data.book_isbn,
      book_language: props.data.book_language,
      is_activated: (isFlagBestseller ? "0" : "1"),
      flag: 'bestseller',
      order_no: flagBestsellerNo,
    }
    props.postBookFlagHandler(data);
  }

  const flagBestSellerNoChangeHandler = (event) => {
    const {
      target: { value },
    } = event;
    setFlagBestsellerNo(value);
    setIsFlagBestseller(false);
  }

  const trendyCheckBoxHandler = () => {
    const data = {
      book_isbn: props.data.book_isbn,
      book_language: props.data.book_language,
      is_activated: isFlagTrendy ? "0" : "1",
      flag: 'trendy',
      order_no: 0,
    }
    props.postBookFlagHandler(data);
  }

  const addToLightSearchHandler = () => {
    const data = {
      book_isbn: props.data.book_isbn,
      book_language: props.data.book_language,
    }
    props.postLightSearchHandler(data);
  }

  const removeLightSearchHandler = () => {
    const data = {
      book_isbn: props.data.book_isbn,
      book_language: props.data.book_language,
      is_delete: true
    }
    props.postLightSearchHandler(data);
  }

  const uploadImageCallBack = (file) => {
    console.log(file);
    // return new Promise(
    //   (resolve, reject) => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.open('POST', '/upload');
    //     xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
    //     const data = new FormData();
    //     data.append('file', file);
    //     data.file = file;
    //     xhr.send(data);
    //     xhr.addEventListener('load', () => {
    //       const response = JSON.parse(xhr.responseText);
    //       console.log(response.url);
    //       resolve({ data: { link: response.url } })
    //     });
    //     xhr.addEventListener('error', () => {
    //       const error = JSON.parse(xhr.responseText);
    //       reject(error);
    //     });
    //   }
    // );
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} className="title_container">
          <Grid xs={12} sm={2} md={1}>
            <img className="image" src={props.data.book_image} style={{ maxWidth: "100px" }} />
          </Grid>
          <Grid xs={12} sm={7} md={5} >
            {props.data.book_title}<br />
            By {props.data.book_author}<br />
            {props.data.book_isbn}<br />
            E-Commerce Price: RM{parseFloat(props.data.ecommerce_price).toFixed(2)}

            <br />
            <br />
          </Grid>
          <Grid xs={12} sm={3} md={6} >
            {!props.data.light_search?.book_isbn && (
              <Button variant="contained" color="success" onClick={addToLightSearchHandler}>Add To Light Search</Button>
            )}
            {props.data.light_search?.book_isbn && (
              <Button variant="contained" color="warning" onClick={removeLightSearchHandler}>Remove From Light Search</Button>
            )}

          </Grid>
        </Grid>



        {/* ---------------------------------------------------------------------------------------------------------------------
       ---------------------------------------------------Flags------------------------------------------------------------
       ------------------------------------------------------------------------------------------------------------------------*/}
        <h2>Flags</h2>

        {props.bookFlags && (
          <>
            <Stack direction="row" spacing={1}>
              <Chip label="Enabled Flags:" variant="filled" />
              {props.bookFlags.map((flag) => (
                <Chip label={flag.b_selected_flag} variant="outlined" />
              ))}
            </Stack>
            <br />
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid xs={6} sm={3} md={2}>
                <div className="flag-options">
                  <ButtonGroup
                    disableElevation
                  >
                    <FormControlLabel checked={isFlagBestseller} control={<Checkbox defaultChecked onClick={bestsellerCheckBoxHandler} />} label="Bestseller" />
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        size="small"
                        fullWidth
                        inputProps={{ step: "1" }}
                        type="number"
                        id="book_stock"
                        name="book_stock"
                        value={flagBestsellerNo}
                        onChange={flagBestSellerNoChangeHandler}
                        onBlur={formik.handleBlur}
                        error={formik.touched.book_stock && Boolean(formik.errors.book_stock)}
                        style={{ border: "0px" }}
                      />
                    </FormControl>
                  </ButtonGroup>
                </div>
              </Grid>
              <Grid xs={6} sm={3} md={2}>
                <FormControlLabel checked={isFlagTrendy} control={<Checkbox defaultChecked onClick={trendyCheckBoxHandler} />} label="Trendy" />
              </Grid>
            </Grid>
          </>
        )}

        {/* ---------------------------------------------------------------------------------------------------------------------
       ---------------------------------------------------Basic Info------------------------------------------------------------
       ------------------------------------------------------------------------------------------------------------------------*/}

        <h2>Basic Info</h2>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              id="book_title"
              name="book_title"
              label="Title"
              value={formik.values.book_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.book_title && Boolean(formik.errors.book_title)}
            // helperText={formik.touched.book_isbn && formik.errors.book_isbn}
            />
          </Grid>
          <Grid xs={6} sm={3} md={3}>
            <TextField
              fullWidth
              id="book_author"
              name="book_author"
              label="Author"
              value={formik.values.book_author}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.book_author && Boolean(formik.errors.book_author)}
            // helperText={formik.touched.book_isbn && formik.errors.book_isbn}
            />
          </Grid>
          <Grid xs={6} sm={3} md={3}>
            <TextField
              fullWidth
              id="book_image"
              name="book_image"
              label="Image URL"
              value={formik.values.book_image}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.book_image && Boolean(formik.errors.book_image)}
            // helperText={formik.touched.book_isbn && formik.errors.book_isbn}
            />
          </Grid>
          <Grid xs={6} sm={2} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="label_book_country_id">Country</InputLabel>
              <Select
                labelId="label_book_country_id"
                id="book_country"
                name="book_country"
                label="Country"
                value={formik.values.book_country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_country && Boolean(formik.errors.book_country)}
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value={"cn"}>China</MenuItem>
                <MenuItem value={"my"}>Malaysia</MenuItem>
                <MenuItem value={"us"}>USA</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={2} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="label_book_language">Language</InputLabel>
              <Select
                labelId="label_book_language"
                id="book_language"
                name="book_language"
                label="Language"
                value={selectedLanguage}
                onChange={languageHandleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_language && Boolean(formik.errors.book_language)}
              >
                {languageOptions.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={4}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="label_book_category_id">Category</InputLabel>
              <Select
                labelId="label_book_category_id"
                id="book_category"
                name="book_category"
                label="Category"
                multiple
                value={selectedCategoryTitles}
                onChange={categoryHandleChange}
                input={<OutlinedInput label="Categroy" />}
                renderValue={renderCategoryListValue}
                MenuProps={MenuProps}
              >
                {languageCategories.map((cagtegory) => (
                  <MenuItem key={cagtegory.cat_code} value={cagtegory.cat_code}>
                    <Checkbox checked={selectedCategories.indexOf(cagtegory.cat_code) > -1} />
                    <ListItemText primary={cagtegory.cat_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={2}>
            <TextField
              fullWidth
              id="book_publisher"
              name="book_publisher"
              label="Publisher"
              value={formik.values.book_publisher}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.book_publisher && Boolean(formik.errors.book_publisher)}
            />
          </Grid>
          <Grid xs={6} sm={3} md={2}>
            <FormControl sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Published Date"
                  // name="book_pub_date"
                  // value={formik.values.book_pub_date}
                  // value={formik.values.book_pub_date}
                  value={selectedPublishedDate}
                  onChange={(newValue) => setSelectedPublishedDate(newValue)}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={2}>
            <TextField
              fullWidth
              id="book_family_id"
              name="family_key"
              label="Family ISBN"
              value={formik.values.family_key}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.family_key && Boolean(formik.errors.family_key)}
            // helperText={formik.touched.book_isbn && formik.errors.book_isbn}
            />
          </Grid>
        </Grid>
        {/* ---------------------------------------------------------------------------------------------------------------------
       ---------------------------------------------------Inventory------------------------------------------------------------
       ------------------------------------------------------------------------------------------------------------------------*/}
        <h2>Inventory</h2>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid xs={6} sm={3} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="book_seller_label_id">Seller</InputLabel>
              <Select
                labelId="book_seller_label_id"
                id="book_seller_id"
                name="book_seller"
                label="Seller"
                value={formik.values.book_seller}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_seller && Boolean(formik.errors.book_seller)}
              >
                {props.bookSellers.map((option) => (
                  <MenuItem value={option.code}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="label_book_currency_id">Currency</InputLabel>
              <Select
                labelId="label_book_currency_id"
                id="book_currency"
                name="book_currency"
                label="Currency"
                value={formik.values.book_currency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_currency && Boolean(formik.errors.book_currency)}
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value={"myr"}>MYR</MenuItem>
                <MenuItem value={"usd"}>USD</MenuItem>
                <MenuItem value={"cny"}>YUAN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="book_format_label_id">Format</InputLabel>
              <Select
                labelId="book_format_label_id"
                id="book_format_id"
                name="book_format"
                label="Format"
                value={formik.values.book_format}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_format && Boolean(formik.errors.book_format)}
              >
                {props.bookFormats.map((format) => (
                  <MenuItem value={format.code}>{format.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="book_price_label_id">List Price</InputLabel>
              <OutlinedInput
                fullWidth
                inputProps={{ step: "1" }}
                type="number"
                id="book_price"
                name="book_price"
                label="List Price"
                value={formik.values.book_price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_price && Boolean(formik.errors.book_price)}
              />
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="book_discount_rate_id">Discount %</InputLabel>
              <OutlinedInput
                fullWidth
                inputProps={{ step: "1" }}
                type="number"
                id="book_discount_rate_id"
                name="book_discount_rate"
                label="Discount %"
                value={formik.values.book_discount_rate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_discount_rate && Boolean(formik.errors.book_discount_rate)}
              />
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="book_weight_id">Weight</InputLabel>
              <OutlinedInput
                fullWidth
                inputProps={{ step: "1" }}
                type="number"
                id="book_weight_id"
                name="book_weight"
                label="Weight"
                value={formik.values.book_weight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_weight && Boolean(formik.errors.book_weight)}
              />
            </FormControl>
          </Grid>
          <Grid xs={6} sm={3} md={3}>
            <ButtonGroup
              disableElevation
            >
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="stock_options_label_id">Stock Options</InputLabel>
                <Select
                  labelId="stock_options_label_id"
                  id="stock_options_id"
                  name="stock_options"
                  label="Stock Options"
                  value={selectedStock}
                  onChange={stockOptionHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.book_seller && Boolean(formik.errors.book_seller)}
                >
                  {props.stockStatuses.map((option) => (
                    <MenuItem value={option.code}>{option.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="book_stock">Stock</InputLabel>
                <OutlinedInput
                  fullWidth
                  disabled={selectedStock == props.stockStatuses[0]?.code ? false : true}
                  inputProps={{ step: "1" }}
                  type="number"
                  id="book_stock"
                  name="book_stock"
                  label="Stock"
                  value={bookStock}
                  onChange={stockChangeHandler}
                  onBlur={formik.handleBlur}
                  error={formik.touched.book_stock && Boolean(formik.errors.book_stock)}
                  style={{ border: "0px" }}

                />
              </FormControl>
            </ButtonGroup>
          </Grid>
          <Grid xs={6} sm={3} md={2}>
            <Button style={{ height: "100%", width: "100%" }} startIcon={<SaveIcon />} color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
        <h2>Rent Inventory</h2>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid xs={6} sm={4} md={2}>
            <Checkbox
              checked={isForRent}
              onClick={rentCheckBoxHandler}
            /> Is For Rent?
          </Grid>
          <Grid xs={6} sm={4} md={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="rent_stock_label_id">Rent Stock</InputLabel>
              <OutlinedInput
                fullWidth
                inputProps={{ step: "1" }}
                type="number"
                id="rent_stock"
                name="rent_stock"
                label="rent Stock"
                value={formik.values.rent_stock}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.book_price && Boolean(formik.errors.book_price)}
                disabled={isForRent ? false : true}
              />
            </FormControl>
          </Grid>
        </Grid>

      </form>
      <br />
      {/* ---------------------------------------------------------------------------------------------------------------------
       ---------------------------------------------------Synopsys------------------------------------------------------------
       ------------------------------------------------------------------------------------------------------------------------*/}
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid xs={12} sm={12} md={12}>
          <Editor
            // // editorState={editorState}
            // toolbarClassName="toolbarClassName"
            // wrapperClassName="wrapperClassName"
            // editorClassName="editorClassName"
            // // onEditorStateChange={this.onEditorStateChange}
            // editorState={editorState}
            // initialState={props.data.book_synopsis}
            // onChange={setEditorState}
            editorState={editorState}
            // onEditorStateChange={setEditorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            uploadImageCallBack={uploadImageCallBack}
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                urlEnabled: true,
                uploadEnabled: true,
                previewImage: true
              },
              embedded: {
                // embedCallback
              }
            }}
          />
        </Grid>

        {/* book_synopsis */}
      </Grid>
      <hr />
    </>


  );
};

export default UpdateBookDetail;
