import * as React from "react";
// import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import dateFormat from "dateformat";
import { MdPayment, MdAttachMoney, MdThumbUp } from "react-icons/md";
import { Navigate } from "react-router-dom";

import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";

// import { Field, Form, Formik, FormikProps } from "formik";

interface IUrlObj {
    requestID: string;
}

interface IProps {
    match: any;
}

interface IRequest {
    match: any;
}


let requestTemplate: any;

const paymentStatusList = [
    "Pending Payment",
    "Payment Done",
    "Payment Canceled"
];

const requestStatusList = {
    "p": "Pending Approval",
    "a": "Approved",
    "c": "Canceled"
};

const WithdrawalRequest: React.FC<IProps> = ({ match }) => {
    const [requestID, setRequestID] = React.useState("");
    const [generalObj, setGeneralObj] = React.useState(requestTemplate);
    const dispatch = useDispatch();
    const requestDetail = useSelector((state: any) => {
        return state.affiliateReducer.details_result;
    });
    const requestDetailLoading = useSelector((state: any) => {
        return state.affiliateReducer.details_loading;
    });
    const postPaymentResult = useSelector((state: any) => {
        return state.affiliateReducer.create_payment_result;
    });
    const postPaymentResultLoading = useSelector((state: any) => {
        return state.affiliateReducer.create_payment_loading;
    });
    const paymentOrdersResult = useSelector((state: any) => {
        return state.affiliateReducer.payment_order_result;
    });
    const paymentOrdersLoading = useSelector((state: any) => {
        return state.affiliateReducer.payment_order_loading;
    });
    const paymentDoneResult = useSelector((state: any) => {
        return state.affiliateReducer.payment_done_result;
    });
    const paymentDoneLoading = useSelector((state: any) => {
        return state.affiliateReducer.payment_done_loading;
    });

    React.useEffect(() => {
        const urlObj: IUrlObj = match.params;
        setRequestID(urlObj.requestID);

        dispatch(actions.getWithdrawalRequestDetails({
            request_id: urlObj.requestID
        }));

        dispatch(actions.emptyPaymentOrders());

        //   effect
        return () => {
            //   cleanup
        };
    }, [match.params]);

    React.useEffect(() => {
        if (postPaymentResult) {
            setGeneralObj({
                ...requestDetail,
                payment: postPaymentResult
            });
        }
        return () => {
            //   cleanup
        };
    }, [postPaymentResult]);

    React.useEffect(() => {
        if (requestDetail) {
            setGeneralObj({
                ...requestDetail
            });
        }
        return () => {
            //   cleanup
        };
    }, [requestDetail]);

    React.useEffect(() => {
        if (paymentDoneResult) {
            dispatch(actions.getWithdrawalRequestDetails({
                request_id: requestID
            }));
        }
        return () => {
            //   cleanup
        };
    }, [paymentDoneResult]);

    const createPaymentActio = () => {
        dispatch(actions.postWithdrawalPayment({
            request_id: requestID,
            program_id: requestDetail.ap_id
        }));
    }

    const getListOfOrdersAction = () => {
        dispatch(actions.getPaymentOrders({
            payment_id: generalObj.payment.payment_id
        }));
    }

    const postPaymentDoneAction = () => {
        dispatch(actions.postPaymentDone({
            payment_id: generalObj.payment.payment_id,
            request_id: generalObj.aw_id,
        }));
    }

    return (
        <Container fluid={true} className="container_div">
            {/* <Row>
                <Col>
                    {JSON.stringify(generalObj)}
                    <hr />
                </Col>
            </Row> */}


            <Row>
                <Col xs="12">
                    {(requestDetailLoading === true || paymentDoneLoading === true) && (
                        <Row>
                            <Col>
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                    )}
                    {requestDetailLoading === false && generalObj && generalObj.aw_id && (
                        <div>
                            <h4>Withdrawal Request Details:</h4>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "50%" }}>Request ID:</td>
                                        <td>{generalObj.aw_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Requested On:</td>
                                        <td>{dateFormat(new Date(generalObj.aw_creation_time), "yyyy-mm-dd HH:MM:ss")}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{requestStatusList[generalObj.aw_status]}</td>
                                    </tr>
                                    <tr>
                                        <td>Advertiser ID:</td>
                                        <td>{generalObj.user_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Advertiser Name:</td>
                                        <td>{generalObj.user_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Advertiser Email:</td>
                                        <td>{generalObj.user_email}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Name:</td>
                                        <td>{generalObj.bank_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Acount Name.:</td>
                                        <td>{generalObj.account_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Acount No.:</td>
                                        <td>{generalObj.account_number}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    {postPaymentResultLoading === true && (
                        <Row>
                            <Col>
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                    )}
                    {postPaymentResultLoading === false && generalObj && generalObj.payment && (
                        <div>
                            <h4>Payment Details:</h4>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "50%" }}>Payment ID:</td>
                                        <td>{generalObj.payment.payment_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{paymentStatusList[generalObj.payment.payment_status]}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Updated:</td>
                                        <td>{(generalObj.payment.last_updated_time ? dateFormat(new Date(generalObj.payment.last_updated_time), "yyyy-mm-dd HH:MM:ss") : "")}</td>
                                    </tr>
                                    <tr>
                                        <td>Pay Amount:</td>
                                        <td>RM{generalObj.payment.payment_amount ? (generalObj.payment.payment_amount).toFixed(2) : ""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Col>
            </Row>
            {generalObj && !generalObj.payment && (
                <Row>
                    <Col xs="4">
                        <Button style={{ marginTop: 0 }} color="primary" block onClick={createPaymentActio}><MdAttachMoney /> Create Payment</Button>
                    </Col>
                </Row>
            )}

            {generalObj && generalObj.payment && generalObj.payment.payment_status === 0 && (
                <Row>
                    <Col xs="4">
                        <Button style={{ marginTop: 0 }} color="success" block onClick={postPaymentDoneAction}><MdThumbUp /> Payment Done!</Button>
                    </Col>
                </Row>
            )}

            <Row style={{ marginTop: 100 }}>
                <Col>
                    <h4>List Of Orders </h4>
                    <hr />
                </Col>
            </Row>
            {generalObj && generalObj.payment && (
                <Row>
                    <Col xs="4">
                        <Button style={{ marginTop: 0 }} color="primary" block onClick={getListOfOrdersAction}><MdPayment /> List of Orders</Button>
                    </Col>
                </Row>
            )}

            <Row>
                <Col xs="12">
                    {paymentOrdersLoading === true && (
                        <Row>
                            <Col>
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                    )}
                    {paymentOrdersLoading === false && paymentOrdersResult.length > 0 && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Customer ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentOrdersResult.map((rowData: any, index) => {
                                    return (
                                        <tr key={rowData.order_id + "_row"}>
                                            <td>{rowData.order_id}</td>
                                            <td>{rowData.customer_id}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row>
        </Container >
    );
};

export default WithdrawalRequest;
