import * as React from "react";
import "./addBookBulk.css";
import dateFormat from "dateformat";

// import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { Button, Col, Form, Input, Label, Row, Spinner, Table, Alert } from "reactstrap";

import { ExcelRenderer } from "react-excel-renderer";

import { MdCheck, MdClose, MdThumbUp } from "react-icons/md";
import MessageModal from "../../ui/messageModal/messageModal";
import { table } from "console";

type Props = {
  dataSyncHandler: any;
  postBookDataResult: any;
  customUpdateResultHandler: any;
  customUpdateResult: any;
};

const AddBookBulk: React.FC<Props> = (props: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(new Array());
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    if (props.customUpdateResult) {
      setMessage(props.customUpdateResult.update_status ? "Book Updated Successfully :)" : "Error Happened :(");
      setShowMessage(true);
    }
  }, [props.customUpdateResult]);

  React.useEffect(() => {
    if (props.postBookDataResult && props.postBookDataResult.book_isbn) {
      let newRowArr: any = [...tableData];
      let updatedRow = -1;
      for (let i = 0; i < newRowArr.length; i++) {
        if (newRowArr[i].book_isbn === props.postBookDataResult.book_isbn) {
          newRowArr[i] = { ...props.postBookDataResult };
          if (i == newRowArr.length - 1) {
            updatedRow = -1;
          }
          else {
            updatedRow = i + 1;
          }
        }
      }
      if (updatedRow != -1) {
        props.dataSyncHandler(tableData[updatedRow]);
        newRowArr[updatedRow].server_flag = 4;
      }

      setTableData(newRowArr);

    }
  }, [props.postBookDataResult]);

  const updateBookHandler = (data, index) => {
    props.customUpdateResultHandler(data);
  };

  const customWeightHandler = (arg, data, index) => {
    let newArr = [...tableData];
    newArr[index].book_weight = arg.target.value;
    setTableData(newArr);
  };

  const customImageHandler = (arg, data, index) => {
    let newArr = [...tableData];
    newArr[index].book_image = arg.target.value;
    setTableData(newArr);
  };

  const selectFileHandler = (event: any) => {
    setIsLoading(true);
    setTableData(new Array());
    const fileObj = event.target.files[0];
    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let tempArray = resp.rows
          .filter((row, index) => {
            if (index === 0 || !row || !row[0] || row[0].toString().length === 0) {
              return false;
            } else {
              return true;
            }
          })
          .map((row, index) => {
            let pubDate = '0000-00-00';
            if (row[10] && typeof row[10] === 'string') {
              pubDate = row[10].toString();
            }
            else if (row[10]) {
              pubDate = dateFormat(new Date(row[10]), "yyyy-mm-dd")
            }

            return {
              book_isbn: row[0] && row[0].toString().length > 0 ? row[0].toString() : "",
              book_title: row[1] && row[1].toString().length > 0 ? row[1].toString() : "",
              book_author: row[2] && row[2].toString().length > 0 ? row[2].toString() : "",
              book_seller: row[3] && row[3].toString().length > 0 ? row[3].toString() : "",
              book_format: row[4] && row[4].toString().length > 0 ? row[4].toString() : "",
              book_price: row[5] && row[5].toString().length > 0 ? row[5].toString() : "0",
              book_discount_rate: row[6] && row[6].toString().length > 0 ? row[6].toString() : "0",
              book_weight: row[7] && row[7].toString().length > 0 ? row[7].toString() : "0",
              book_category: row[8] && row[8].toString().length > 0 ? "-" + row[8].toString() + "-" : "",
              book_publisher: row[9] && row[9].toString().length > 0 ? row[9].toString() : "",
              book_pub_date: pubDate,
              book_currency: row[11] && row[11].toString().length > 0 ? row[11].toString() : "myr",
              book_language: row[12] && row[12].toString().length > 0 ? row[12].toString() : "en",
              book_country: row[13] && row[13].toString().length > 0 ? row[13].toString() : "my",
              book_stock: row[14] && row[14].toString().length > 0 ? row[14].toString() : "0",
              book_image: row[15] && row[15].toString().length > 0 ? row[15].toString() : "",
              book_synopsis: row[16] && row[16].toString().length > 0 ? row[16].toString() : "",
              server_flag: 2
            };
          });
        setTableData(tempArray);
        setIsLoading(false);
      }
    });
  };

  const startUploadingBooksHandler = () => {
    let copiedData: any = [...tableData];
    copiedData[0].server_flag = 4;
    setTableData(copiedData);
    props.dataSyncHandler(tableData[0]);
  };

  const closeModalHandler = () => {
    setMessage("");
    setShowMessage(false);
  };

  return (
    <Form>
      <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
      <br />

      <Alert color="info">
        Please follow &nbsp;
        <a href="https://bookurvestore.sgp1.cdn.digitaloceanspaces.com/general/template/bulk_upload_template.xlsx" target="_blank">
          this template.
        </a>
        <br />
        - If the book does not exist, it will be inserted. <br />
        - If the book exist, then these properties will be updated only:
        <ul>
          <li>Seller</li>
          <li>Price</li>
          <li>Stock</li>
          <li>Discount</li>
          <li>Currency</li>
        </ul>

        *Take note that for updating all these properties are required.
      </Alert>


      <Row className="no-padding">
        <Col xs="4" md="4" className="padding-side-5">
          <input style={{}} type="file" onChange={selectFileHandler} />
        </Col>
      </Row>
      <Row className="no-padding">
        <Col className="spinner_container">{isLoading && <Spinner color="dark" />}</Col>
      </Row>

      {tableData.length > 0 && (
        <>
          <Row>
            <Col style={{ marginTop: "10px", paddingLeft: 5, }}>
              <Button color="primary" onClick={startUploadingBooksHandler}>UPLOAD DATA</Button>
            </Col>
          </Row>

          <br /><br />

          <Table style={{ width: "100%" }} striped>
            <thead>
              <tr>
                <th>ISBN</th>
                <th>Title</th>


              </tr>
            </thead>
            <tbody>
              {tableData.map((rowData: any, index) => {
                return (
                  <tr key={"row_" + index} className="">
                    <React.Fragment key={"second_component" + index}>
                      <td className="table_cell_isbn" key={"book_isbn" + index}>
                        {rowData.book_isbn}
                      </td>
                      <td className="table_cell_name" key={"book_title" + index}>
                        {rowData.book_title.substr(0, 30)}
                      </td>
                      {rowData.server_flag !== 2 && (
                        <React.Fragment key={"third_component" + index}>
                          <td className="table_cell" key={"book_image" + index}>
                            {rowData.server_flag !== 2 && <img className="table_img" src={rowData.book_image} alt="" />}
                          </td>
                          <td className="table_cell_img" key={"book_data_img_" + index}>
                            <Label>Image URL</Label>
                            <Input
                              key={"input_key_img_" + index}
                              value={rowData.book_image}
                              onChange={event => customImageHandler(event, rowData, index)}
                              className="table_input_url"
                            />
                          </td>
                          <td className="table_cell" key={"book_data_weight_" + index}>
                            <Label>Weight</Label>
                            <Input
                              id={"input_key_img_" + index}
                              key={"input_key_img_" + index}
                              value={rowData.book_weight}
                              onChange={event => customWeightHandler(event, rowData, index)}
                              className="table_input_weight"
                            />
                          </td>
                          <td className="table_cell_save" key={"book_data_save_" + index}>
                            <Button size="sm" color="primary" onClick={() => updateBookHandler(rowData, index)}>
                              Save
                            </Button>
                          </td>
                        </React.Fragment>
                      )}

                      <td className="table_cell_result" key={"final_col_" + index}>
                        {rowData.server_flag === 0 && <>Problem</>}
                        {rowData.server_flag === 1 && <>Inserted</>}
                        {rowData.server_flag === 2 && (<>Waiting...</>)}
                        {rowData.server_flag === 3 && <>Exist</>}
                        {rowData.server_flag === 4 && (<>Uploading</>)}
                      </td>
                    </React.Fragment>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </Form>
  );
};

export default AddBookBulk;
