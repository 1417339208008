import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getOrdersInit = () => {
    return {
        type: actionType.GET_ORDER_INIT
    };
};

const getOrdersFailure = (err: any) => {
    return {
        type: actionType.GET_ORDER_FAILURE,
        payload: err
    };
};

const getOrdersSuccess = (result: any) => {
    return {
        type: actionType.GET_ORDER_SUCCESS,
        payload: result
    };
};

const clearOrdersData = () => {
    return {
        type: actionType.CLEAR_ORDER
    };
};

export const getOrders = (data: any): any => {
    return (dispatch: any) => {
        dispatch(getOrdersInit());

        bookAPI
            .getOrders(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(getOrdersSuccess(result.data));
                } else {
                    dispatch(getOrdersFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(getOrdersFailure(err));
            });
    };
};

export const clearOrders = (): any => {
    return (dispatch: any) => {
        dispatch(clearOrdersData());
    };
};
