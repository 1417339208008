import React from "react";
import globalFunction from "../../util/globalFunctions";


import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";


const Home: React.FC = () => {
  const glob = new globalFunction();

  const [passphraseText, setPassphraseText] = React.useState("");
  const [hashedText, setHashedText] = React.useState("");

  const passphraseTextAction = () => {
    let hashedPassPhrase = glob.getHashOfString(passphraseText);
    setHashedText(hashedPassPhrase);
  }

  const setPassphraseTextHandler = (event: any) => {
    setPassphraseText(event.target.value);
  };

  return (
    <Container fluid={true} className="container_div">
      <Row>
        <Col>
          <h1>Home</h1>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col xs="6" md="4">
            <FormGroup>
              <Label for="passphraseText">Plain Text</Label>
              <Input
                type="text"
                name="passphrase_text"
                id="passphraseText"
                placeholder="Pass Phrase"
                value={passphraseText}
                onChange={setPassphraseTextHandler}
              />
            </FormGroup>
          </Col>
          <Col xs="6" md="4">
            <FormGroup>
              <Label for="hashedText">Hashed Text</Label>
              <Input
                type="text"
                name="hashed_text"
                id="hashedText"
                placeholder="Hashed Text"
                value={hashedText}
              />
            </FormGroup>
          </Col>
          <Col xs="5" md="4">
            <Button style={{ marginTop: 30 }} color="primary" block onClick={passphraseTextAction}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      {/* <Button color="danger">Danger!</Button> */}
    </Container>
  );
};

export default Home;
