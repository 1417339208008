import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const postBookInit = () => {
  return {
    type: actionType.POST_BOOK_INIT
  };
};

const postBookFailure = (err: any) => {
  return {
    type: actionType.POST_BOOK_FAILURE,
    payload: err
  };
};

const postBookSuccess = (result: any) => {
  return {
    type: actionType.POST_BOOK_SUCCESS,
    payload: result
  };
};

const updateBookInit = () => {
  return {
    type: actionType.UPDATE_CUSTOM_BOOK_INIT
  };
};

const updateBookFailure = (err: any) => {
  return {
    type: actionType.UPDATE_CUSTOM_BOOK_FAILURE,
    payload: err
  };
};

const updateBookSuccess = (result: any) => {
  return {
    type: actionType.UPDATE_CUSTOM_BOOK_SUCCESS,
    payload: result
  };
};

export const postBookData = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(postBookInit());

    bookAPI
      .postAddNewBook(postData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postBookSuccess(result.data));
        } else {
          dispatch(postBookFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postBookFailure(err));
      });
  };
};

export const postCustomBookUpdate = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(updateBookInit());

    bookAPI
      .postCustomBookUpdate(postData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(updateBookSuccess(result.data));
        } else {
          dispatch(updateBookFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(updateBookFailure(err));
      });
  };
};


// ------------------------------------------------ SEARCH BOOK

const searchBookInit = () => {
  return {
    type: actionType.SEARCH_BOOK_INIT
  };
};

const searchBookFailure = (err: any) => {
  return {
    type: actionType.SEARCH_BOOK_FAILURE,
    payload: err
  };
};

const searchBookSuccess = (result: any) => {
  return {
    type: actionType.SEARCH_BOOK_SUCCESS,
    payload: result
  };
};

export const searchBookByIsbn = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(searchBookInit());

    bookAPI
      .searchBookByIsbn(postData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(searchBookSuccess(result.data));
        } else {
          dispatch(searchBookFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(searchBookFailure(err));
      });
  };
};
// ------------------------------------------------ BOOK CATEGORY LIST

const getBookCategoryListInit = () => {
  return {
    type: actionType.BOOK_CATEGORY_LIST_INIT
  };
};

const getBookCategoryListFailure = (err: any) => {
  return {
    type: actionType.BOOK_CATEGORY_LIST_FAILURE,
    payload: err
  };
};

const getBookCategoryListSuccess = (result: any) => {
  return {
    type: actionType.BOOK_CATEGORY_LIST_SUCCESS,
    payload: result
  };
};

export const getBookCategoryList = (): any => {
  return (dispatch: any) => {
    dispatch(getBookCategoryListInit());

    bookAPI
      .getBookCategoryList()
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBookCategoryListSuccess(result.data));
        } else {
          dispatch(getBookCategoryListFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBookCategoryListFailure(err));
      });
  };
};
// ------------------------------------------------ Update Book Details

const updateBookDetailsInit = () => {
  return {
    type: actionType.UPDATE_BOOK_INIT
  };
};

const updateBookDetailsFailure = (err: any) => {
  return {
    type: actionType.UPDATE_BOOK_FAILURE,
    payload: err
  };
};

const updateBookDetailsSuccess = (result: any) => {
  return {
    type: actionType.UPDATE_BOOK_SUCCESS,
    payload: result
  };
};

export const updateBookDetails = (postData: any): any => {
  return (dispatch: any) => {
    dispatch(updateBookDetailsInit());

    bookAPI
      .updateBookDetails(postData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(updateBookDetailsSuccess(result.data));
        } else {
          dispatch(updateBookDetailsFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(updateBookDetailsFailure(err));
      });
  };
};

export const resetUpdateBookDetails = () => {
  return {
    type: actionType.UPDATE_BOOK_RESET
  };
};

// ------------------------------------------------ GET Book Flags

const getBookFlagInit = () => {
  return {
    type: actionType.GET_FLAG_INIT
  };
};

const getBookFlagFailure = (err: any) => {
  return {
    type: actionType.GET_FLAG_FAILURE,
    payload: err
  };
};

const getBookFlagSuccess = (result: any) => {
  return {
    type: actionType.GET_FLAG_SUCCESS,
    payload: result
  };
};

export const getBookFlags = (getData: any): any => {
  return (dispatch: any) => {
    dispatch(getBookFlagInit());

    bookAPI
      .getBookFlags(getData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBookFlagSuccess(result.data));
        } else {
          dispatch(getBookFlagFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBookFlagFailure(err));
      });
  };
};

// ------------------------------------------------ POST Book Flags

const postBookFlagInit = () => {
  return {
    type: actionType.POST_FLAG_INIT
  };
};

const postBookFlagFailure = (err: any) => {
  return {
    type: actionType.POST_FLAG_FAILURE,
    payload: err
  };
};

const postBookFlagSuccess = (result: any) => {
  return {
    type: actionType.POST_FLAG_SUCCESS,
    payload: result
  };
};

export const postBookFlags = (getData: any): any => {
  return (dispatch: any) => {
    dispatch(postBookFlagInit());

    bookAPI
      .postBookFlags(getData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postBookFlagSuccess(result.data));
        } else {
          dispatch(postBookFlagFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postBookFlagFailure(err));
      });
  };
};
// ------------------------------------------------ POST Light Search

const postLightSearchInit = () => {
  return {
    type: actionType.POST_LIGHT_SEARCH_INIT
  };
};

const postLightSearchFailure = (err: any) => {
  return {
    type: actionType.POST_LIGHT_SEARCH_FAILURE,
    payload: err
  };
};

const postLightSearchSuccess = (result: any) => {
  return {
    type: actionType.POST_LIGHT_SEARCH_SUCCESS,
    payload: result
  };
};

export const postLightSearch = (getData: any): any => {
  return (dispatch: any) => {
    dispatch(postLightSearchInit());
    
    bookAPI
      .postLightSearch(getData)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postLightSearchSuccess(result.data));
        } else {
          dispatch(postLightSearchFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postLightSearchFailure(err));
      });
  };
};
