import * as actionTypes from "../../actions/actionType";

const initialState = {
  error: {},
  list_loading: false,
  list_result: new Array(),
  search_adv_loading: false,
  search_adv_result: new Array(),
  details_loading: false,
  details_result: new Array(),
  create_payment_loading: false,
  create_payment_result: new Array(),
  payment_order_loading: false,
  payment_order_result: new Array(),
  payment_done_loading: false,
  payment_done_result: null,
  advertiser_details_loading: false,
  advertiser_details_result: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_WITHDRAWAL_REQUEST_LIST_INIT:
      return { ...state, list_loading: true, error: "", list_result: new Array() };
    case actionTypes.GET_WITHDRAWAL_REQUEST_LIST_FAILURE:
      return { ...state, list_loading: false, error: action.payload };
    case actionTypes.GET_WITHDRAWAL_REQUEST_LIST_SUCCESS:
      return { ...state, list_loading: false, list_result: action.payload };
    case actionTypes.GET_SEARCH_ADVERTISER_INIT:
      return { ...state, search_adv_loading: true, error: "", search_adv_result: new Array() };
    case actionTypes.GET_SEARCH_ADVERTISER_FAILURE:
      return { ...state, search_adv_loading: false, error: action.payload };
    case actionTypes.GET_SEARCH_ADVERTISER_SUCCESS:
      return { ...state, search_adv_loading: false, search_adv_result: action.payload };
    case actionTypes.GET_WITHDRAWAL_REQUEST_DETAILS_INIT:
      return { ...state, details_loading: true, error: "", details_result: new Array() };
    case actionTypes.GET_WITHDRAWAL_REQUEST_DETAILS_FAILURE:
      return { ...state, details_loading: false, error: action.payload };
    case actionTypes.GET_WITHDRAWAL_REQUEST_DETAILS_SUCCESS:
      return { ...state, details_loading: false, details_result: action.payload };
    case actionTypes.POST_WITHDRAWAL_PAYMENT_DETAILS_INIT:
      return { ...state, create_payment_loading: true, error: "", create_payment_result: new Array() };
    case actionTypes.POST_WITHDRAWAL_PAYMENT_DETAILS_FAILURE:
      return { ...state, create_payment_loading: false, error: action.payload };
    case actionTypes.POST_WITHDRAWAL_PAYMENT_DETAILS_SUCCESS:
      return { ...state, create_payment_loading: false, create_payment_result: action.payload };
      case actionTypes.EMPTY_PAYMENT_ORDERS_LIST_INIT:
      return { ...state, payment_order_loading: false, error: "", payment_order_result: new Array() };
    case actionTypes.GET_PAYMENT_ORDERS_LIST_INIT:
      return { ...state, payment_order_loading: true, error: "", payment_order_result: new Array() };
    case actionTypes.GET_PAYMENT_ORDERS_LIST_FAILURE:
      return { ...state, payment_order_loading: false, error: action.payload };
    case actionTypes.GET_PAYMENT_ORDERS_LIST_SUCCESS:
      return { ...state, payment_order_loading: false, payment_order_result: action.payload };
    case actionTypes.POST_PAYMENT_DONE_INIT:
      return { ...state, payment_done_loading: true, error: "", payment_done_result: null };
    case actionTypes.POST_PAYMENT_DONE_FAILURE:
      return { ...state, payment_done_loading: false, error: action.payload };
    case actionTypes.POST_PAYMENT_DONE_SUCCESS:
      return { ...state, payment_done_loading: false, payment_done_result: action.payload };
    case actionTypes.GET_ADVERTISER_DETAILS_INIT:
      return { ...state, advertiser_details_loading: true, error: "", advertiser_details_result: null };
    case actionTypes.GET_ADVERTISER_DETAILS_FAILURE:
      return { ...state, advertiser_details_loading: false, error: action.payload };
    case actionTypes.GET_ADVERTISER_DETAILS_SUCCESS:
      return { ...state, advertiser_details_loading: false, advertiser_details_result: action.payload };
    default:
      return state;
  }
};

export default reducer;