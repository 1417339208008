import * as React from "react";
import "./batch.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import moment from 'moment';
import { Link } from "react-router-dom";
import LoadingModal from "../../../components/ui/loadingModal/loadingModal";

import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

// import { Field, Form, Formik, FormikProps } from "formik";

interface IUrlObj {
    bookISBN: string;
}

interface IProps {
    match: any;
}

const Batch: React.FC<IProps> = ({ match }) => {
    const [searchKey, setSearchKey] = React.useState("");

    //START: State Selector
    const dispatch = useDispatch();
    const pageLoadingS = useSelector((state: any) => {
        return state.tradeinReducer.loading_loading;
    });
    const batchListResult = useSelector((state: any) => {
        return state.tradeinReducer.get_btc_lst_result;
    });
    //END: State Selector
    const searchBatchAction = () => {
        let data = {
            user_id: searchKey
        };
        dispatch(actions.getBatchList(data));
    };

    const searchKeywordHandler = (event: any) => {
        setSearchKey(event.target.value);
    };

    return (
        <Container fluid={true} className="container_div">
            {pageLoadingS && <LoadingModal showModal={true} />}
            <Row>
                <Col>
                    {/* {JSON.stringify(batchListResult)} */}
                    <h1>Batch</h1>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col xs="6" md="4">
                        <FormGroup>
                            <Label for="exampleEmail">User ID</Label>
                            <Input
                                type="text"
                                name="search_batch"
                                id="searchBatch"
                                placeholder="User ID"
                                value={searchKey}
                                onChange={searchKeywordHandler}
                            />
                        </FormGroup>
                    </Col>
                    <Col />
                    <Col xs="5" md="4">
                        <Button style={{ marginTop: 30 }} color="primary" block onClick={searchBatchAction}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
            {batchListResult && (
                <table className="bundle_table">
                    <thead>
                        <tr>
                            <th>
                                User ID
                            </th>
                            <th>
                                User Name
                            </th>
                            <th>
                                Date
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(batchListResult).map(index => {
                            return (
                                <tr>
                                    <td>{batchListResult[index].user_id}</td>
                                    <td>{batchListResult[index].user_name}</td>
                                    <td>
                                        {moment(batchListResult[index].gbb_timestamp).format("YYYY/MM/DD")}
                                    </td>
                                    <td>{(batchListResult[index].gbb_completed === 0 ? "Pending" : "Completed")}</td>
                                    <td>
                                        <Link to={"/batch-details/" + batchListResult[index].user_id + "/" + batchListResult[index].gbb_id + "/"}>more...</Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
        </Container>
    );
};

export default Batch;
