import React, { useState } from "react";
import globalFunction from "../../util/globalFunctions";
import "./order.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import { Container, Row, Col } from "reactstrap";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { Button, ListSubheader, Box, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ORDER_SEARCH_OPTIONS } from '../../util/const'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        paddingLeft: "5px",
        paddingRight: "0px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingLeft: "5px",
        paddingRight: "0px",
        minWidth: "50px"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Order: React.FC = () => {
    const glob = new globalFunction();
    const dispatch = useDispatch();
    const [selectedSearchOpt, setSelectedSearchOpt] = useState("");

    const onGetExOrderClick = () => {
        const data = {
            type: "order_status",
            keyword: "r",
            page_size: 10,
            page_number: 0
        }
        dispatch(actions.getOrders(data));
    };

    const orderList = useSelector((state: any) => {
        return state.orderReducer.orderList;
    });

    const orderLoading = useSelector((state: any) => {
        return state.orderReducer.orderLoading;
    });

    const onSearchOptChanged = (event) => {
        alert(event.target.value);
        setSelectedSearchOpt(event.target.value);
    }

    return (
        <Container fluid={true} className="container_div">
            {(orderLoading) && <LoadingModal showModal={true} />}
            <Row>
                <Col>
                    <h1>Internal Orders</h1>
                </Col>
            </Row>
            <Grid container style={{ paddingBottom: "50px", justifyContent: "flex-end" }}  >
                <Grid xs={6} md={4} >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Options</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedSearchOpt}
                            label="Options"
                            onChange={onSearchOptChanged}
                        >
                            <MenuItem value={""}>Select One</MenuItem>
                            <ListSubheader className="select_group">By Order Status</ListSubheader>
                            {ORDER_SEARCH_OPTIONS.status.map((obj) => {
                                return (
                                    <MenuItem value={obj.value}>{obj.text}</MenuItem>
                                )
                            })}
                            <ListSubheader className="select_group">By Other Methods</ListSubheader>
                            {ORDER_SEARCH_OPTIONS.others.map((obj) => {
                                return (
                                    <MenuItem value={obj.value}>{obj.text}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid md={4} >

                </Grid>

                <Grid xs={6} md={4}>
                    <Button fullWidth variant="contained" onClick={onGetExOrderClick} sx={{ height: "100%" }}>Get List</Button>
                </Grid>
            </Grid>
            <Box>
                <Box>
                    Total Orders: {orderList.length}
                </Box>
            </Box>
            {orderList.length > 0 && orderList?.map((row) => (
                <Box className="card_container">
                    <Box className="card_title">
                        <Box className="card_title_left">
                            Order ID: {row.order_id}
                        </Box>
                        <Box className="card_title_center">
                            {row.order_status_txt}
                        </Box>
                        <Box className="card_title_right">
                            {moment(row.order_timestamp).format("YYYY-MM-DD")}
                        </Box>
                    </Box>
                    <Box className="card_body">
                        <Grid container className="card_section_container">
                            <Grid xs={12} md={4} className="card_section">
                                <Box>Customer Details:</Box>
                                <Box>{row.order_fullname}</Box>
                                <Box>User ID: {row.user_id}</Box>
                                <Box>Contact: {row.order_phone}</Box>
                            </Grid>
                            <Grid xs={12} md={4} className="card_section">
                                <Box>Ship To:</Box>
                                {`${row.order_address}, ${row.order_city} ${row.order_zip} ${row.order_state}, ${row.order_country}`}
                            </Grid>
                            <Grid xs={12} md={4} className="card_section">
                                <Box>Payment:</Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            {(row.order_items && row.order_items.length > 0) && row.order_items.map((item) => (
                                <Grid xs={12} md={4} className="item_container">
                                    <Box className="item_sub item_image">
                                        <img src={item.book_image} />
                                    </Box>
                                    <Box className="item_sub item_info">
                                        <div>{item.book_title}</div>
                                        <div>{item.book_isbn}</div>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {/* <StyledTableCell>{row.user_id}</StyledTableCell>
                    <StyledTableCell className="cap-cell">{row.order_fullname}</StyledTableCell>
                    <StyledTableCell className="cap-cell">{row.order_state}</StyledTableCell>
                    <StyledTableCell>{row.order_status_txt}</StyledTableCell>
                    <StyledTableCell>{row.order_duration_txt}</StyledTableCell>
                    <StyledTableCell>{parseFloat(row.order_amount).toFixed(2)}</StyledTableCell>
                    <StyledTableCell className="bold-cell">{row.order_bankname}</StyledTableCell>
                    <StyledTableCell>{row.order_receipt}</StyledTableCell>
                    <StyledTableCell><Button variant="outlined">Details</Button></StyledTableCell> */}
                </Box>
            ))}
        </Container>
    );
};

export default Order;
