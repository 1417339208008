import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getWithdrawalRequesListInit = () => {
  return {
    type: actionType.GET_WITHDRAWAL_REQUEST_LIST_INIT
  };
};

const getWithdrawalRequestListFailure = (err: any) => {
  return {
    type: actionType.GET_WITHDRAWAL_REQUEST_LIST_FAILURE,
    payload: err
  };
};

const getWithdrawalRequestListSuccess = (result: any) => {
  return {
    type: actionType.GET_WITHDRAWAL_REQUEST_LIST_SUCCESS,
    payload: result
  };
};


export const getWithdrawalRequestList = (data): any => {
  return (dispatch: any) => {
    dispatch(getWithdrawalRequesListInit());

    bookAPI
      .getWithdrawalRequestList(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getWithdrawalRequestListSuccess(result.data));
        } else {
          dispatch(getWithdrawalRequestListFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getWithdrawalRequestListFailure(err));
      });
  };
};

//--------------------- getSearchAdvertiser
const getSearchAdvertiserInit = () => {
  return {
    type: actionType.GET_SEARCH_ADVERTISER_INIT
  };
};

const getSearchAdvertiserFailure = (err: any) => {
  return {
    type: actionType.GET_SEARCH_ADVERTISER_FAILURE,
    payload: err
  };
};

const getSearchAdvertiserSuccess = (result: any) => {
  return {
    type: actionType.GET_SEARCH_ADVERTISER_SUCCESS,
    payload: result
  };
};

export const getSearchAdvertiser = (data): any => {
  return (dispatch: any) => {
    dispatch(getSearchAdvertiserInit());

    bookAPI
      .getSearchAdvertiser(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getSearchAdvertiserSuccess(result.data));
        } else {
          dispatch(getSearchAdvertiserFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getSearchAdvertiserFailure(err));
      });
  };
};

//----------------------get Affiliate Details
const getWithdrawalRequesDetailsInit = () => {
  return {
    type: actionType.GET_WITHDRAWAL_REQUEST_DETAILS_INIT
  };
};

const getWithdrawalRequestDetailsFailure = (err: any) => {
  return {
    type: actionType.GET_WITHDRAWAL_REQUEST_DETAILS_FAILURE,
    payload: err
  };
};

const getWithdrawalRequestDetailsSuccess = (result: any) => {
  return {
    type: actionType.GET_WITHDRAWAL_REQUEST_DETAILS_SUCCESS,
    payload: result
  };
};


export const getWithdrawalRequestDetails = (data): any => {
  return (dispatch: any) => {
    dispatch(getWithdrawalRequesDetailsInit());

    bookAPI
      .getWithdrawalRequestDetails(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getWithdrawalRequestDetailsSuccess(result.data));
        } else {
          dispatch(getWithdrawalRequestDetailsFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getWithdrawalRequestDetailsFailure(err));
      });
  };
};

//----------------------Post Payment 
const postWithdrawalPaymentInit = () => {
  return {
    type: actionType.POST_WITHDRAWAL_PAYMENT_DETAILS_INIT
  };
};

const postWithdrawalPaymentFailure = (err: any) => {
  return {
    type: actionType.POST_WITHDRAWAL_PAYMENT_DETAILS_FAILURE,
    payload: err
  };
};

const postWithdrawalPaymentSuccess = (result: any) => {
  return {
    type: actionType.POST_WITHDRAWAL_PAYMENT_DETAILS_SUCCESS,
    payload: result
  };
};


export const postWithdrawalPayment = (data): any => {
  return (dispatch: any) => {
    dispatch(postWithdrawalPaymentInit());

    bookAPI
      .postWithdrawalPaymentCreate(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postWithdrawalPaymentSuccess(result.data));
        } else {
          dispatch(postWithdrawalPaymentFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postWithdrawalPaymentFailure(err));
      });
  };
};

//----------------------get Payment orders
const getPaymentOrdersInit = () => {
  return {
    type: actionType.GET_PAYMENT_ORDERS_LIST_INIT
  };
};

const getPaymentOrdersFailure = (err: any) => {
  return {
    type: actionType.GET_PAYMENT_ORDERS_LIST_FAILURE,
    payload: err
  };
};

const getPaymentOrdersSuccess = (result: any) => {
  return {
    type: actionType.GET_PAYMENT_ORDERS_LIST_SUCCESS,
    payload: result
  };
};


export const getPaymentOrders = (data): any => {
  return (dispatch: any) => {
    dispatch(getPaymentOrdersInit());

    bookAPI
      .getAffiliateOrdersForPayment(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getPaymentOrdersSuccess(result.data));
        } else {
          dispatch(getPaymentOrdersFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getPaymentOrdersFailure(err));
      });
  };
};

export const emptyPaymentOrders = (): any => {
  return {
    type: actionType.EMPTY_PAYMENT_ORDERS_LIST_INIT
  };
};

//----------------------get Payment Done
const postPaymentDoneInit = () => {
  return {
    type: actionType.POST_PAYMENT_DONE_INIT
  };
};

const postPaymentDoneFailure = (err: any) => {
  return {
    type: actionType.POST_PAYMENT_DONE_FAILURE,
    payload: err
  };
};

const postPaymentDoneSuccess = (result: any) => {
  return {
    type: actionType.POST_PAYMENT_DONE_SUCCESS,
    payload: result
  };
};


export const postPaymentDone = (data): any => {
  return (dispatch: any) => {
    dispatch(postPaymentDoneInit());

    bookAPI
      .postPaymentDone(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postPaymentDoneSuccess(result.data));
        } else {
          dispatch(postPaymentDoneFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postPaymentDoneFailure(err));
      });
  };
};

//----------------------get Advertiser Details
const getAdvertiserDetailsInit = () => {
  return {
    type: actionType.GET_ADVERTISER_DETAILS_INIT
  };
};

const getAdvertiserDetailsFailure = (err: any) => {
  return {
    type: actionType.GET_ADVERTISER_DETAILS_FAILURE,
    payload: err
  };
};

const getAdvertiserDetailsSuccess = (result: any) => {
  return {
    type: actionType.GET_ADVERTISER_DETAILS_SUCCESS,
    payload: result
  };
};

export const getAdvertiserDetails = (data): any => {
  return (dispatch: any) => {
    dispatch(getAdvertiserDetailsInit());

    bookAPI
      .getAdvertiserDetails(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getAdvertiserDetailsSuccess(result.data));
        } else {
          dispatch(getAdvertiserDetailsFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getAdvertiserDetailsFailure(err));
      });
  };
};