import React from "react";
// import "./App.css";
import { BrowserRouter as BRouter, Routes, Route } from "react-router-dom";

import AddNewBook from "../containers/addNewBook/addNewBook";
import Book from "../containers/book/book";
import Home from "../containers/home/home";
import User from "../containers/user/user";
import Nav from "../containers/nav/nav";
import Login from "../containers/login/login";
import Bundle from "../containers/bundle/bundle";
import FlashSale from "../containers/flashSale/flashSale";
import BatchItem from "../containers/tradein/batchItem/batchItem";
import Batch from "../containers/tradein/batch/batch";
import Affiliate from "../containers/affiliate/affiliate";
import WithdrawalRequest from "../containers/withdrawalRequest/withdrawalRequest";
import AffiliateAdvertiser from "../containers/affiliateAdvertiser/affiliateAdvertiser";
import Voucher from "../containers/voucher/voucher";
import Stock from "../containers/stock/stock";
import ExternalOrder from "../containers/externalOrder/externalOrder";
import Order from "../containers/order/order";

const Router: React.FC = () => {
  return (
    <BRouter>
      <div>
        <Nav />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/book/:bookISBN?" element={<Book match />} />
          <Route path="/affiliate/" element={<Affiliate match />} />
          <Route path="/withdrawal-request/:requestID" element={<WithdrawalRequest match />} />
          <Route path="/advertiser/:advertiserID" element={<AffiliateAdvertiser match />} />
          <Route path="/add-new-book/" element={<AddNewBook match />} />
          <Route path="/add-new-book/" element={<AddNewBook match />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/bundle/" element={<Bundle />} />
          <Route path="/flash-sale/" element={<FlashSale />} />
          <Route path="/user/" element={<User match />} />
          <Route path="/batch-details/:userId/:batchId?" element={<BatchItem />} />
          <Route path="/batch/" element={<Batch match />} />
          <Route path="/voucher/" element={<Voucher match />} />
          <Route path='/stock/:shop_id?' element={<Stock />} />
          <Route path='/external-order/' element={<ExternalOrder />} />
          <Route path='/order/' element={<Order />} />

          {/* <Route path="/book/:isbn" component={Book} /> */}
        </Routes>
      </div>
    </BRouter>
  );
};

export default Router;
