import * as actionTypes from "../../actions/actionType";

const initialState = {
  error: {},
  loading_loading: false,
  get_batchid_result: {},
  get_add_sea_btc_itm_result: {},
  post_btc_itm_result: {},
  get_btc_itm_result: {},
  delete_btc_itm_result: null,
  get_btc_lst_result: {},
  post_btc_cnf_status_result: {}
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_BATCHID_INIT:
      return { ...state, loading_loading: true, error: "", get_batchid_result: {} };
    case actionTypes.GET_BATCHID_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.GET_BATCHID_SUCCESS:
      return { ...state, loading_loading: false, get_batchid_result: action.payload };
    case actionTypes.GET_SEA_BTC_ITM_INIT:
      return { ...state, loading_loading: true, error: "", get_add_sea_btc_itm_result: {} };
    case actionTypes.GET_SEA_BTC_ITM_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.GET_SEA_BTC_ITM_SUCCESS:
      return { ...state, loading_loading: false, get_add_sea_btc_itm_result: action.payload };
    case actionTypes.POST_BTC_ITM_INIT:
      return { ...state, loading_loading: true, error: "", post_btc_itm_result: {} };
    case actionTypes.POST_BTC_ITM_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.POST_BTC_ITM_SUCCESS:
      return { ...state, loading_loading: false, post_btc_itm_result: action.payload };
    case actionTypes.GET_BAT_ITM_INIT:
      return { ...state, loading_loading: true, error: "", get_btc_itm_result: {} };
    case actionTypes.GET_BAT_ITM_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.GET_BAT_ITM_SUCCESS:
      return { ...state, loading_loading: false, get_btc_itm_result: action.payload };
    case actionTypes.DELETE_BAT_ITM_INIT:
      return { ...state, loading_loading: true, error: "", delete_btc_itm_result: null };
    case actionTypes.DELETE_BAT_ITM_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.DELETE_BAT_ITM_SUCCESS:
      return { ...state, loading_loading: false, delete_btc_itm_result: action.payload };
    case actionTypes.GET_BAT_LST_INIT:
      return { ...state, loading_loading: true, error: "", get_btc_lst_result: {} };
    case actionTypes.GET_BAT_LST_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.GET_BAT_LST_SUCCESS:
      return { ...state, loading_loading: false, get_btc_lst_result: action.payload };
    case actionTypes.POST_BTC_CNF_STATUS_INIT:
      return { ...state, loading_loading: true, error: "", post_btc_cnf_status_result: {} };
    case actionTypes.POST_BTC_CNF_STATUS_FAILURE:
      return { ...state, loading_loading: false, error: action.payload };
    case actionTypes.POST_BTC_CNF_STATUS_SUCCESS:
      return { ...state, loading_loading: false, post_btc_cnf_status_result: action.payload };
    default:
      return state;
  }
};

export default reducer;