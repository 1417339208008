import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getVoucherInit = () => {
    return {
        type: actionType.GET_VOUCHER_INIT
    };
};

const getVoucherFailure = (err: any) => {
    return {
        type: actionType.GET_VOUCHER_FAILURE,
        payload: err
    };
};

const getVoucherSuccess = (result: any) => {
    return {
        type: actionType.GET_VOUCHER_SUCCESS,
        payload: result
    };
};

export const getVoucher = (data): any => {
    return (dispatch: any) => {
        dispatch(getVoucherInit());

        bookAPI
            .getVoucher(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(getVoucherSuccess(result.data));
                } else {
                    dispatch(getVoucherFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(getVoucherFailure(err));
            });
    };
};

const postVoucherInit = () => {
    return {
        type: actionType.POST_VOUCHER_INIT
    };
};

const postVoucherFailure = (err: any) => {
    return {
        type: actionType.POST_VOUCHER_FAILURE,
        payload: err
    };
};

const postVoucherSuccess = (result: any) => {
    return {
        type: actionType.POST_VOUCHER_SUCCESS,
        payload: result
    };
};

export const postVoucher = (data): any => {
    return (dispatch: any) => {
        dispatch(postVoucherInit());

        bookAPI
            .postVoucher(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(postVoucherSuccess(result.data));
                } else {
                    dispatch(postVoucherFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(postVoucherFailure(err));
            });
    };
};

//----------------- POST Voucher Status

const postVoucherStatusInit = () => {
    return {
        type: actionType.POST_VOUCHER_ST_INIT
    };
};

const postVoucherStatusFailure = (err: any) => {
    return {
        type: actionType.POST_VOUCHER_ST_FAILURE,
        payload: err
    };
};

const postVoucherStatusSuccess = (result: any) => {
    return {
        type: actionType.POST_VOUCHER_ST_SUCCESS,
        payload: result
    };
};

export const postVoucherStatus = (data): any => {
    return (dispatch: any) => {
        dispatch(postVoucherStatusInit());

        bookAPI
            .postVoucherStatus(data)
            .then((result: any) => {
                if (result.status === "success") {
                    dispatch(postVoucherStatusSuccess(result.data));
                } else {
                    dispatch(postVoucherStatusFailure(result.data));
                }
            })
            .catch((err: any) => {
                dispatch(postVoucherStatusFailure(err));
            });
    };
};