import * as React from "react";
import "./flashSale.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import dateFormat from "dateformat";

import { Button, Col, Container, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";

// import { Field, Form, Formik, FormikProps } from "formik";

const FlashSale: React.FC = props => {
  const [saleISBN, setSaleISBN] = React.useState(new Array());
  const dispatch = useDispatch();
  const saleListResult = useSelector((state: any) => {
    return state.saleReducer.list_result;
  });
  const listLoading = useSelector((state: any) => {
    return state.saleReducer.list_loading;
  });
  const postLoading = useSelector((state: any) => {
    return state.saleReducer.post_loading;
  });
  const postSaleResult = useSelector((state: any) => {
    return state.saleReducer.post_result;
  });
  const deleteSaleResult = useSelector((state: any) => {
    return state.saleReducer.delete_result;
  });
  const error = useSelector((state: any) => {
    return state.saleReducer.error;
  });

  const cacheMsg = useSelector((state: any) => {
    return state.cacheReducer.msg;
  });

  const refreshSaleList = () => {
    dispatch(actions.getSaleList());
  };

  React.useEffect(() => {
    dispatch(actions.getSaleList());
    return () => {
      //   cleanup
    };
  }, [postSaleResult, deleteSaleResult]);

  React.useEffect(() => {
    if (error && error.length > 0) {
      alert(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (cacheMsg && cacheMsg.length > 0) {
      alert(cacheMsg);
      dispatch(actions.clearCasheMessage());
    }
  }, [cacheMsg]);

  const createNewSaleHandler = (values: any, action: any) => {
    action.resetForm();
    setSaleISBN(values.new_isbn);
    dispatch(actions.postSale({ book_isbn: values.new_isbn }));
  };

  const deleteSaleHandler = bunObj => {
    dispatch(actions.deleteSale(bunObj));
  };

  const clearCache = () => {
    dispatch(actions.deleteCache({ keyword: "/flash-promotion/" }));
  };

  return (
    <Container fluid={true} className="container_div">
      <Row>
        <Col xs="6">
          <h1>Flash Sale</h1>
        </Col>
        <Col xs="6">
          <Button style={{ width: "100%", maxWidth: 250, float: "right" }} color="info" onClick={clearCache}>
            Clear Cache
          </Button>
        </Col>
      </Row>
      <Formik
        initialValues={{
          new_isbn: ""
        }}
        validate={(values: any) => {
          let errors: any = {};
          if (!values.new_isbn) {
            errors.new_isbn = "ISBN is Required";
          }
          return errors;
        }}
        onSubmit={createNewSaleHandler}
      >
        {(formProps) => (
          <Form>
            <Row>
              <Col xs="12" md="3" className="custom_formik_col">
                <Label>ISBN</Label>
                <Field type="text" name="new_isbn" placeholder="ISBN" />
                {/* <ErrorMessage name="book_isbn" className="formik_error" /> */}
                <ErrorMessage name="new_isbn">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
              </Col>
              <Col xs="6" md="3" className="custom_formik_col">
                <Button type="submit" style={{ marginTop: 20 }} color="primary" block>
                  + ISBN
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <hr />
      {listLoading === true && (
        <Row>
          <Col>
            <Spinner color="primary" />
          </Col>
        </Row>
      )}
      {listLoading === false && saleListResult.length > 0 && (
        <table className="sale_table">
          <tbody>
            {saleListResult.map((rowData: any, index) => {
              return (
                <tr key={rowData.book_isbn + "_row"}>
                  <td>
                    <img src={rowData.book_image} alt="" className="sale_image" />
                  </td>
                  <td>
                    {rowData.book_title}
                    <br />
                    {rowData.book_isbn}
                  </td>
                  <td>{dateFormat(new Date(rowData.fs_timestamp), "yyyy-mm-dd")}</td>
                  <td>
                    <Button
                      color="danger"
                      block
                      onClick={() => {
                        deleteSaleHandler(rowData);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                  <td>
                    <a href={"http://www.bookurve.com:8080/cms/cms_book?action=book_search&book_isbn=" + rowData.book_isbn} target="_blank">
                      ⇨
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Container>
  );
};

export default FlashSale;
