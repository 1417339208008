import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line
import $ from "jquery";
// eslint-disable-next-line
import Popper from "popper.js";

import "bootstrap/dist/js/bootstrap.bundle.min";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import "./style/generalStyle.css";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";

import { CookiesProvider } from 'react-cookie';

import authReducer from "./store/reducers/auth/auth";
import bookReducer from "./store/reducers/book/book";
import bundleReducer from "./store/reducers/bundle/bundle";
import saleReducer from "./store/reducers/sale/sale";
import cacheReducer from "./store/reducers/cache/cache";
import userReducer from "./store/reducers/user/user";
import tradeinReducer from "./store/reducers/tradein/tradein";
import affiliateReducer from "./store/reducers/affiliate/affiliate";
import voucherReducer from "./store/reducers/voucher/voucher";
import externalOrderReducer from "./store/reducers/external-order/external-order";
import orderReducer from "./store/reducers/order/order";

import { render } from "react-dom";


declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  authReducer,
  bookReducer,
  bundleReducer,
  saleReducer,
  cacheReducer,
  userReducer,
  tradeinReducer,
  affiliateReducer,
  voucherReducer,
  externalOrderReducer,
  orderReducer
});
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

// const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
render(
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
