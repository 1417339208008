export const ORDER_SEARCH_OPTIONS = {
    status: [
        {
            text: "Pending Orders",
            value: "p",
            type: "order_status"
        },
        {
            text: "Not Paid Orders",
            value: "n",
            type: "order_status"
        },
        {
            text: "Received Order",
            value: "r",
            type: "order_status"
        },
        {
            text: "Shipped Orders",
            value: "s",
            type: "order_status"
        },
        {
            text: "Done Orders",
            value: "d",
            type: "order_status",
        },
        {
            text: "Rejected Orders",
            value: "c",
            type: "order_status",
        },
        {
            text: "Problem Orders",
            value: "z",
            type: "order_status",
        },
    ],
    others: [
        {
            text: "Order ID",
            value: "order_id",
            type: "order_id"
        },
        {
            text: "PO TAG",
            value: "order_po",
            type: "order_po"
        },
        {
            text: "Tracking Code",
            value: "order_tracking",
            type: "order_tracking"
        },        
        {
            text: "Customer ID",
            value: "user_id",
            type: "user_id"
        },
        {
            text: "Book ISBN",
            value: "book_isbn",
            type: "book_isbn",
        }
    ]
};