import * as React from "react";
// import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import dateFormat from "dateformat";
import { MdRefresh, MdArrowForward } from "react-icons/md";
import { Navigate } from "react-router-dom";

import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";

// import { Field, Form, Formik, FormikProps } from "formik";

interface IProps {
    match: any;
}

interface IUrlObj {
    advertiserID: string;
}

const AffiliateAdvertiser: React.FC<IProps> = ({ match }) => {

    const requestStatusList = {
        "p": "Pending Approval",
        "a": "Approved",
        "c": "Canceled"
    };

    const [advertiserID, setAdvertiserID] = React.useState("");
    const [selectedRequest, setSelectedRequest] = React.useState("");
    const [redirectToWithdrawalRequest, setRedirectToWithdrawalRequest] = React.useState(false);

    const dispatch = useDispatch();

    const detailsLoading = useSelector((state: any) => {
        return state.affiliateReducer.advertiser_details_loading;
    });
    const detailsResult = useSelector((state: any) => {
        return state.affiliateReducer.advertiser_details_result;
    });
    const requestListresult = useSelector((state: any) => {
        return state.affiliateReducer.list_result;
    });
    const requestListLoading = useSelector((state: any) => {
        return state.affiliateReducer.list_loading;
    });
    // const searchAdvertiserAction = () => {
    //     dispatch(actions.getSearchAdvertiser({
    //         keyword: searchKey,
    //         type: selectedOption
    //     }));
    // };

    React.useEffect(() => {
        const urlObj: IUrlObj = match.params;
        setAdvertiserID(urlObj.advertiserID);
        dispatch(actions.getAdvertiserDetails({
            advertiser_id: urlObj.advertiserID
        }));
        dispatch(actions.getWithdrawalRequestList({
            advertiser_id: urlObj.advertiserID,
            status: "all"
        }));
    }, [match.params]);

    const WithdrawalRequestListAction = () => {
        // alert(searchKey);
        dispatch(actions.getWithdrawalRequestList({
            advertiser_id: advertiserID,
            status: "all"
        }));
    };

    const redirectWithdrawalRequestAction = (aw_id) => {
        setSelectedRequest(aw_id);
        setRedirectToWithdrawalRequest(true);
    };

    return (
        <Container fluid={true} className="container_div">
            {redirectToWithdrawalRequest && <Navigate to={"/withdrawal-request/" + selectedRequest} />}
            {/* {JSON.stringify(detailsResult)} */}
            <Row>
                <Col>
                    <h2>Advertiser</h2>
                </Col>
            </Row>

            {detailsLoading === true && (
                <Row>
                    <Col>
                        <Spinner color="primary" />
                    </Col>
                </Row>
            )}
            {detailsLoading === false && detailsResult && (
                <div>
                    <Table borderless>
                        <tbody>
                            <tr>
                                <td>Advertiser ID:</td>
                                <td>{detailsResult.user_id}</td>
                            </tr>
                            <tr>
                                <td>Advertiser Name:</td>
                                <td>{detailsResult.user_name}</td>
                            </tr>
                            <tr>
                                <td>Advertiser Email:</td>
                                <td>{detailsResult.user_email}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <br /><br />

                    {detailsResult.programs.map((rowData: any, index) => {
                        return (
                            <Row key={rowData.ap_id}>
                                <Col>
                                    <h4>Program #{rowData.ap_id}</h4>
                                    <Table size="sm">
                                        <tbody>
                                            <tr>
                                                <th>Join Date:</th>
                                                <td>{dateFormat(new Date(rowData.ap_timestamp), "yyyy-mm-dd HH:MM:ss")}</td>
                                            </tr>
                                            <tr>
                                                <td>Reward Type:</td>
                                                <td>{rowData.ap_reward_type}</td>
                                            </tr>
                                            <tr>
                                                <td>Reward Value:</td>
                                                <td>{rowData.ap_reward_value}</td>
                                            </tr>
                                            <tr>
                                                <td>Customer Duration:</td>
                                                <td>{rowData.ap_customer_duration}</td>
                                            </tr>
                                            <tr>
                                                <td>Earn Percentage:</td>
                                                <td>{rowData.am_advertiser_percentage}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Earning:</td>
                                                <td>{rowData.advertiser_earning}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Sales:</td>
                                                <td>{rowData.total_affiliate_sales}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Orders:</td>
                                                <td>{rowData.total_affiliate_orders}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            )}

            <Row>
                <Col xs="12"><hr /></Col>
                <Col>
                    <h3>Withdrawal Request History</h3>
                </Col>

                <Col xs="4">
                    <Button style={{ marginTop: 0 }} color="primary" block onClick={WithdrawalRequestListAction}><MdRefresh /> List</Button>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    {requestListLoading === true && (
                        <Row>
                            <Col>
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                    )}
                    {requestListLoading === false && requestListresult.length > 0 && (
                        <Table hover size="sm">
                            <thead>
                                <tr>
                                    <th>
                                        REQUEST ID
                                    </th>
                                    <th>
                                        REQESTED DATE
                                    </th>
                                    <th>
                                        STATUS
                                    </th>
                                    <th>
                                        PROGRAM ID
                                    </th>
                                    <th>
                                        ADVERTISER ID
                                    </th>
                                    <th>
                                        DETAIL
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {requestListresult.map((rowData: any, index) => {
                                    return (
                                        <tr key={rowData.aw_id + "_row"}>
                                            <td>{rowData.aw_id}</td>
                                            <td>{dateFormat(new Date(rowData.aw_creation_time), "yyyy-mm-dd")}</td>
                                            <td>{requestStatusList[rowData.aw_status]}</td>
                                            <td>{rowData.ap_id}</td>
                                            <td>{rowData.advertiser_id}</td>
                                            <td style={{ cursor: "pointer" }} onClick={() => redirectWithdrawalRequestAction(rowData.aw_id)}><MdArrowForward /></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row>
        </Container >
    );
};

export default AffiliateAdvertiser;
