import React from "react";
import "./login.css";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";
import * as actions from "../../store/actions/index";
import { useDispatch, useSelector } from "react-redux";
// import Cryptr from "cryptr";
import AES from "crypto-js/aes";
import globalFunction from "../../util/globalFunctions";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const glob = new globalFunction();

  const handleLoginSubmit = (values, actionss) => {
    let hashedPassPhrase = glob.getHashOfString(values.secure_phrase);
    let encryptedPhrase = glob.encryptPassPhrase(hashedPassPhrase);
    dispatch(
      actions.login({
        secure_phrase: encryptedPhrase
      })
    );
  };

  return (
    <Container fluid={true} className="container_div">
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} className="login_container">
          <div className="login_header">Login</div>
          <Row>
            <Col>
              <Formik
                initialValues={{
                  secure_phrase: ""
                }}
                validate={(values: any) => {
                  let errors: any = {};
                  if (!values.secure_phrase) {
                    errors.secure_phrase = "Secure Phrase is Required";
                  }
                  return errors;
                }}
                onSubmit={handleLoginSubmit}
              >
                {(formProps) => (
                  <Form>
                    <Row>
                      <Col xs="12" className="custom_formik_col">
                        <Label>Secure Phrase</Label>
                        <Field type="text" name="secure_phrase" placeholder="Secure Phrase" />
                        <ErrorMessage name="secure_phrase">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
                      </Col>
                    </Row>
                    <br />
                    <Button color="primary" type="submit">
                      Submit Form
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
