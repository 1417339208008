import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import LoadingModal from "../../components/ui/loadingModal/loadingModal";
import MessageModal from "../../components/ui/messageModal/messageModal";
import UpdateBookDetail from "../../components/sub/updateBookDetail/updateBookDetail";
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, Button, Container } from '@mui/material';
import { useFormik } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import { useParams, useNavigate } from 'react-router-dom';

interface IUrlObj {
  bookISBN: string;
}

interface IProps {
  match: any;
}

const Book: React.FC<IProps> = ({ match }) => {
  const [flags, setFlags] = React.useState([]);
  const dispatch = useDispatch();
  const { bookISBN } = useParams();
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [refreshData, setRefreshData] = React.useState(true);
  const navigate = useNavigate();

  const closeModalHandler = () => {
    setMessage("");
    setShowMessage(false);
  };

  React.useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      getBookDetailsByISBN();
    }
  }, [refreshData]);

  const getBookDetailsByISBN = () => {
    if (bookISBN && bookISBN.length > 0) {
      const data = {
        book_isbn: bookISBN
      }
      dispatch(actions.searchBookByIsbn(data));
    }
  }

  const formik = useFormik({
    initialValues: { search_book: bookISBN },

    onSubmit: (values) => {
      if (values?.search_book && values.search_book.length > 0) {
        navigate(`/book/${values.search_book}/`);
        setRefreshData(true);
      }
    },
  });

  const searchResult = useSelector((state: any) => {
    return state.bookReducer.searchBookResult;
  });

  const searchLoading = useSelector((state: any) => {
    return state.bookReducer.searchBookLoading;
  });

  const bookCategories = useSelector((state: any) => {
    return state.bookReducer.categoryListResult;
  });

  const updateBookDetailsStatus = useSelector((state: any) => {
    return state.bookReducer.updateBkDetailsResult;
  });

  const updateBookDetailsLoading = useSelector((state: any) => {
    return state.bookReducer.updateBkDetailsLoading;
  });

  const bookFlags = useSelector((state: any) => {
    return state.bookReducer.getBookFlagsResult;
  });

  const bookFlagsLoading = useSelector((state: any) => {
    return state.bookReducer.getBookFlagsLoading;
  });

  const postBookFlags = useSelector((state: any) => {
    return state.bookReducer.postBookFlagsResult;
  });

  const postBookFlagsLoading = useSelector((state: any) => {
    return state.bookReducer.postBookFlagsLoading;
  });

  const postLightSearchLoading = useSelector((state: any) => {
    return state.bookReducer.postLightSearchLoading;
  });

  const postLightSearchResult = useSelector((state: any) => {
    return state.bookReducer.postLightSearchResult;
  });

  React.useEffect(() => {
    if (!bookCategories) {
      dispatch(actions.getBookCategoryList());
    }
  }, []);

  React.useEffect(() => {
    if (searchResult && searchResult.book_isbn) {
      dispatch(actions.getBookFlags({ book_isbn: searchResult.book_isbn }));
    }
  }, [searchResult]);

  React.useEffect(() => {
    if (bookFlags) {
      setFlags(bookFlags);
    }
  }, [bookFlags]);

  React.useEffect(() => {
    if (postBookFlags) {
      setFlags(postBookFlags);
    }
  }, [postBookFlags]);

  React.useEffect(() => {
    if (updateBookDetailsStatus && updateBookDetailsStatus == 'updated') {
      setMessage("Book detail is updated.");
      setShowMessage(true);
    }
    return () => {
      //   cleanup      
      dispatch(actions.resetUpdateBookDetails());
    };
  }, [updateBookDetailsStatus]);

  React.useEffect(() => {
    if (postLightSearchResult) {
      getBookDetailsByISBN();
      setMessage("Light search is updated.");
      setShowMessage(true);
    }
  }, [postLightSearchResult]);

  React.useEffect(() => {
    if (match.params) {
      const urlObj: IUrlObj = match.params;
      alert(urlObj);
      // setSearchKey(urlObj.bookISBN);
    }
    //   effect
    return () => {
      //   cleanup
    };
  }, [match.params]);

  const updateBookDetailsResult = useSelector((state: any) => {
    return state.bookReducer.updateBook;
  });

  const updateBookDetailsHandler = (bookData?: any) => {
    dispatch(actions.updateBookDetails(bookData));
  };

  const postBookFlagHandler = (postData?: any) => {
    dispatch(actions.postBookFlags(postData));
  };

  const postLightSearchHandler = (postData?: any) => {
    dispatch(actions.postLightSearch(postData));
  };

  return (
    <Container className="container_div">
      <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
      {(postLightSearchLoading || updateBookDetailsLoading || searchLoading || postBookFlagsLoading) && <LoadingModal showModal={true} />}

      <form onSubmit={formik.handleSubmit}>
        <Grid style={{ paddingTop: 20 }} container rowSpacing={2} columnSpacing={1}>
          <Grid xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              id="search_book_id"
              name="search_book"
              label="Book ISBN"
              value={formik.values.search_book}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.search_book && Boolean(formik.errors.search_book)}
            // helperText={formik.touched.book_isbn && formik.errors.book_isbn}
            />
          </Grid>
          <Grid xs={6} sm={3} md={3} mdOffset={6}>
            <Button style={{ height: "100%", width: "100%" }} startIcon={<SearchIcon />} color="primary" variant="outlined" type="submit">
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
      <hr />

      {
        searchResult && searchResult.book_isbn && (
          <UpdateBookDetail
            updateDataHandler={updateBookDetailsHandler}
            updateDataResult={updateBookDetailsResult}
            data={searchResult}
            englishCategories={bookCategories && bookCategories.english_category ? bookCategories.english_category : null}
            chineseCategories={bookCategories && bookCategories.chinese_category ? bookCategories.chinese_category : null}
            malayCategories={bookCategories && bookCategories.malay_category ? bookCategories.malay_category : null}
            bookSellers={bookCategories && bookCategories.book_sellers ? bookCategories.book_sellers : null}
            childrenCategories={bookCategories && bookCategories.children_category ? bookCategories.children_category : null}
            bookFormats={bookCategories && bookCategories.book_formats ? bookCategories.book_formats : null}
            stockStatuses={bookCategories && bookCategories.stock_statuses ? bookCategories.stock_statuses : null}
            bookFlags={flags}
            bookFlagsLoading={bookFlagsLoading}
            postBookFlagHandler={postBookFlagHandler}
            postLightSearchHandler={postLightSearchHandler}
            postLightSearchLoading={postLightSearchLoading}
          />
        )
      }
    </Container >
  );
};

export default Book;
