// LOGIN
export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CHECK_LOGIN_INIT = "CHECK_LOGIN_INIT";
export const CHECK_LOGIN_FAILURE = "CHECK_LOGIN_FAILURE";
export const CHECK_LOGIN_SUCCESS = "CHECK_LOGIN_SUCCESS";
export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
// BOOK
export const POST_BOOK_INIT = "POST_BOOK_INIT";
export const POST_BOOK_FAILURE = "POST_BOOK_FAILURE";
export const POST_BOOK_SUCCESS = "POST_BOOK_SUCCESS";
export const UPDATE_CUSTOM_BOOK_INIT = "UPDATE_CUSTOM_BOOK_INIT";
export const UPDATE_CUSTOM_BOOK_FAILURE = "UPDATE_CUSTOM_BOOK_FAILURE";
export const UPDATE_CUSTOM_BOOK_SUCCESS = "UPDATE_CUSTOM_BOOK_SUCCESS";
export const SEARCH_BOOK_INIT = "SEARCH_BOOK_INIT";
export const SEARCH_BOOK_FAILURE = "SEARCH_BOOK_FAILURE";
export const SEARCH_BOOK_SUCCESS = "SEARCH_BOOK_SUCCESS";
export const BOOK_CATEGORY_LIST_INIT = "BOOK_CATEGORY_LIST_INIT";
export const BOOK_CATEGORY_LIST_FAILURE = "BOOK_CATEGORY_LIST_FAILURE";
export const BOOK_CATEGORY_LIST_SUCCESS = "BOOK_CATEGORY_LIST_SUCCESS";
export const UPDATE_BOOK_INIT = "UPDATE_BOOK_INIT";
export const UPDATE_BOOK_FAILURE = "UPDATE_BOOK_FAILURE";
export const UPDATE_BOOK_SUCCESS = "UPDATE_BOOK_SUCCESS";
export const UPDATE_BOOK_RESET = "UPDATE_BOOK_RESET";
// FLAG
export const GET_FLAG_INIT = "GET_FLAG_INIT";
export const GET_FLAG_FAILURE = "GET_FLAG_FAILURE";
export const GET_FLAG_SUCCESS = "GET_FLAG_SUCCESS";
export const POST_FLAG_INIT = "POST_FLAG_INIT";
export const POST_FLAG_FAILURE = "POST_FLAG_FAILURE";
export const POST_FLAG_SUCCESS = "POST_FLAG_SUCCESS";
//LIGHT SEARCH
export const POST_LIGHT_SEARCH_INIT = "POST_LIGHT_SEARCH_INIT";
export const POST_LIGHT_SEARCH_FAILURE = "POST_LIGHT_SEARCH_FAILURE";
export const POST_LIGHT_SEARCH_SUCCESS = "POST_LIGHT_SEARCH_SUCCESS";
//BUNDLE
export const GET_BUNDLE_LIST_INIT = "GET_BUNDLE_LIST_INIT";
export const GET_BUNDLE_LIST_FAILURE = "GET_BUNDLE_LIST_FAILURE";
export const GET_BUNDLE_LIST_SUCCESS = "GET_BUNDLE_LIST_SUCCESS";
export const POST_BUNDLE_INIT = "POST_BUNDLE_INIT";
export const POST_BUNDLE_FAILURE = "POST_BUNDLE_FAILURE";
export const POST_BUNDLE_SUCCESS = "POST_BUNDLE_SUCCESS";
export const DELETE_BUNDLE_INIT = "DELETE_BUNDLE_INIT";
export const DELETE_BUNDLE_FAILURE = "DELETE_BUNDLE_FAILURE";
export const DELETE_BUNDLE_SUCCESS = "DELETE_BUNDLE_SUCCESS";
// SALE
export const GET_SALE_LIST_INIT = "GET_SALE_LIST_INIT";
export const GET_SALE_LIST_FAILURE = "GET_SALE_LIST_FAILURE";
export const GET_SALE_LIST_SUCCESS = "GET_SALE_LIST_SUCCESS";
export const POST_SALE_INIT = "POST_SALE_INIT";
export const POST_SALE_FAILURE = "POST_SALE_FAILURE";
export const POST_SALE_SUCCESS = "POST_SALE_SUCCESS";
export const DELETE_SALE_INIT = "DELETE_SALE_INIT";
export const DELETE_SALE_FAILURE = "DELETE_SALE_FAILURE";
export const DELETE_SALE_SUCCESS = "DELETE_SALE_SUCCESS";
//CACHE
export const DELETE_CACHE_INIT = "DELETE_CACHE_INIT";
export const DELETE_CACHE_FAILURE = "DELETE_CACHE_FAILURE";
export const DELETE_CACHE_SUCCESS = "DELETE_CACHE_SUCCESS";
export const CLEAR_CACHE_MSG = "CLEAR_CACHE_MSG";
//USER
export const GET_USER_INIT = "GET_USER_INIT";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const UPDATE_USER_INFO_INIT = "UPDATE_USER_INFO_INIT";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILURE = "UPDATE_USER_INFO_FAILURE";
export const UPDATE_USER_PASS_INIT = "UPDATE_USER_PASS_INIT";
export const UPDATE_USER_PASS_SUCCESS = "UPDATE_USER_PASS_SUCCESS";
export const UPDATE_USER_PASS_FAILURE = "UPDATE_USER_PASS_FAILURE";
//TRADEIN
export const GET_BATCHID_INIT = "GET_BATCHID_INIT";
export const GET_BATCHID_FAILURE = "GET_BATCHID_FAILURE";
export const GET_BATCHID_SUCCESS = "GET_BATCHID_SUCCESS";
export const GET_SEA_BTC_ITM_INIT = "GET_SEA_BTC_ITM_INIT";
export const GET_SEA_BTC_ITM_FAILURE = "GET_SEA_BTC_ITM_FAILURE";
export const GET_SEA_BTC_ITM_SUCCESS = "GET_SEA_BTC_ITM_SUCCESS";
export const POST_BTC_ITM_INIT = "POST_BTC_ITM_INIT";
export const POST_BTC_ITM_FAILURE = "POST_BTC_ITM_FAILURE";
export const POST_BTC_ITM_SUCCESS = "POST_BTC_ITM_SUCCESS";
export const GET_BAT_ITM_INIT = "GET_BAT_ITM_INIT";
export const GET_BAT_ITM_FAILURE = "GET_BAT_ITM_FAILURE";
export const GET_BAT_ITM_SUCCESS = "GET_BAT_ITM_SUCCESS";
export const DELETE_BAT_ITM_INIT = "DELETE_BAT_ITM_INIT";
export const DELETE_BAT_ITM_FAILURE = "DELETE_BAT_ITM_FAILURE";
export const DELETE_BAT_ITM_SUCCESS = "DELETE_BAT_ITM_SUCCESS";
export const GET_BAT_LST_INIT = "GET_BAT_LST_INIT";
export const GET_BAT_LST_FAILURE = "GET_BAT_LST_FAILURE";
export const GET_BAT_LST_SUCCESS = "GET_BAT_LST_SUCCESS";
export const POST_BTC_CNF_STATUS_INIT = "POST_BTC_CNF_STATUS_INIT";
export const POST_BTC_CNF_STATUS_FAILURE = "POST_BTC_CNF_STATUS_FAILURE";
export const POST_BTC_CNF_STATUS_SUCCESS = "POST_BTC_CNF_STATUS_SUCCESS";
//AFFILIATE
export const GET_WITHDRAWAL_REQUEST_LIST_INIT = "GET_WITHDRAWAL_REQUEST_LIST_INIT";
export const GET_WITHDRAWAL_REQUEST_LIST_FAILURE = "GET_WITHDRAWAL_REQUEST_LIST_FAILURE";
export const GET_WITHDRAWAL_REQUEST_LIST_SUCCESS = "GET_WITHDRAWAL_REQUEST_LIST_SUCCESS";
export const GET_SEARCH_ADVERTISER_INIT = "GET_SEARCH_ADVERTISER_INIT";
export const GET_SEARCH_ADVERTISER_FAILURE = "GET_SEARCH_ADVERTISER_FAILURE";
export const GET_SEARCH_ADVERTISER_SUCCESS = "GET_SEARCH_ADVERTISER_SUCCESS";
export const GET_WITHDRAWAL_REQUEST_DETAILS_INIT = "GET_WITHDRAWAL_REQUEST_DETAILS_INIT";
export const GET_WITHDRAWAL_REQUEST_DETAILS_FAILURE = "GET_WITHDRAWAL_REQUEST_DETAILS_FAILURE";
export const GET_WITHDRAWAL_REQUEST_DETAILS_SUCCESS = "GET_WITHDRAWAL_REQUEST_DETAILS_SUCCESS";
//Affilliate Payment
export const POST_WITHDRAWAL_PAYMENT_DETAILS_INIT = "POST_WITHDRAWAL_PAYMENT_DETAILS_INIT";
export const POST_WITHDRAWAL_PAYMENT_DETAILS_FAILURE = "POST_WITHDRAWAL_PAYMENT_DETAILS_FAILURE";
export const POST_WITHDRAWAL_PAYMENT_DETAILS_SUCCESS = "POST_WITHDRAWAL_PAYMENT_DETAILS_SUCCESS";
export const EMPTY_PAYMENT_ORDERS_LIST_INIT = "EMPTY_PAYMENT_ORDERS_LIST_INIT";
export const GET_PAYMENT_ORDERS_LIST_INIT = "GET_PAYMENT_ORDERS_LIST_INIT";
export const GET_PAYMENT_ORDERS_LIST_FAILURE = "GET_PAYMENT_ORDERS_LIST_FAILURE";
export const GET_PAYMENT_ORDERS_LIST_SUCCESS = "GET_PAYMENT_ORDERS_LIST_SUCCESS";
export const POST_PAYMENT_DONE_INIT = "POST_PAYMENT_DONE_INIT";
export const POST_PAYMENT_DONE_FAILURE = "POST_PAYMENT_DONE_FAILURE";
export const POST_PAYMENT_DONE_SUCCESS = "POST_PAYMENT_DONE_SUCCESS";
//Advertiser
export const GET_ADVERTISER_DETAILS_INIT = "GET_ADVERTISER_DETAILS_INIT";
export const GET_ADVERTISER_DETAILS_FAILURE = "GET_ADVERTISER_DETAILS_FAILURE";
export const GET_ADVERTISER_DETAILS_SUCCESS = "GET_ADVERTISER_DETAILS_SUCCESS";
//Voucher
export const GET_VOUCHER_INIT = "GET_VOUCHER_INIT";
export const GET_VOUCHER_FAILURE = "GET_VOUCHER_FAILURE";
export const GET_VOUCHER_SUCCESS = "GET_VOUCHER_SUCCESS";
export const POST_VOUCHER_INIT = "POST_VOUCHER_INIT";
export const POST_VOUCHER_FAILURE = "POST_VOUCHER_FAILURE";
export const POST_VOUCHER_SUCCESS = "POST_VOUCHER_SUCCESS";
export const POST_VOUCHER_ST_INIT = "POST_VOUCHER_ST_INIT";
export const POST_VOUCHER_ST_FAILURE = "POST_VOUCHER_ST_FAILURE";
export const POST_VOUCHER_ST_SUCCESS = "POST_VOUCHER_ST_SUCCESS";
//External Order
export const GET_EX_ORDER_INIT = "GET_EX_ORDER_INIT";
export const GET_EX_ORDER_FAILURE = "GET_EX_ORDER_FAILURE";
export const GET_EX_ORDER_SUCCESS = "GET_EX_ORDER_SUCCESS";
export const CLEAR_EX_ORDER = "CLEAR_EX_ORDER";
//order
export const GET_ORDER_INIT = "GET_ORDER_INIT";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const CLEAR_ORDER = "CLEAR_ORDER";
