import * as actionTypes from "../../actions/actionType";

const initialState = {
    error: {},
    exOrderLoading: false,
    exOrderList: {}
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.GET_EX_ORDER_INIT:
            return { ...state, exOrderLoading: true, error: "" };
        case actionTypes.GET_EX_ORDER_FAILURE:
            return { ...state, exOrderLoading: false, error: action.payload };
        case actionTypes.GET_EX_ORDER_SUCCESS:
            return { ...state, exOrderLoading: false, exOrderList: action.payload };
        case actionTypes.CLEAR_EX_ORDER:
            return { ...state, exOrderLoading: false, exOrderList: [] };
        default:
            return state;
    }
};

export default reducer;
