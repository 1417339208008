import * as React from "react";
import "./bundle.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import dateFormat from "dateformat";

import { Button, Col, Container, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";

// import { Field, Form, Formik, FormikProps } from "formik";

const Bundle: React.FC = props => {
  const [bundleISBNs, setBundleISBNs] = React.useState(new Array());
  const dispatch = useDispatch();
  const bundleListResult = useSelector((state: any) => {
    return state.bundleReducer.list_result;
  });
  const listLoading = useSelector((state: any) => {
    return state.bundleReducer.list_loading;
  });
  const postLoading = useSelector((state: any) => {
    return state.bundleReducer.post_loading;
  });
  const postBundleResult = useSelector((state: any) => {
    return state.bundleReducer.post_result;
  });
  const deleteBundleResult = useSelector((state: any) => {
    return state.bundleReducer.delete_result;
  });
  const error = useSelector((state: any) => {
    return state.bundleReducer.error;
  });
  const cacheMsg = useSelector((state: any) => {
    return state.cacheReducer.msg;
  });

  const refreshBundleList = () => {
    dispatch(actions.getBundleList());
  };

  React.useEffect(() => {
    dispatch(actions.getBundleList());
    return () => {
      //   cleanup
    };
  }, [postBundleResult, deleteBundleResult]);

  React.useEffect(() => {
    if (error && error.length > 0) {
      alert(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (cacheMsg && cacheMsg.length > 0) {
      alert(cacheMsg);
      dispatch(actions.clearCasheMessage());
    }
  }, [cacheMsg]);

  const addNewIsbnHandler = (values: any, actions: any) => {
    actions.resetForm();
    setBundleISBNs([...bundleISBNs, values.new_isbn]);
  };

  const clearIsbnList = () => {
    setBundleISBNs(new Array());
  };

  const createNewBundleHandler = () => {
    dispatch(actions.postBundle({ isbn_list: bundleISBNs }));
  };

  const deleteBundleHandler = bunObj => {
    dispatch(actions.deleteBundle(bunObj));
  };

  const clearCache = () => {
    dispatch(actions.deleteCache({ keyword: "/bundle/" }));
  };

  return (
    <Container fluid={true} className="container_div">
      <Row>
        <Col xs="6">
          <h1>Bundle</h1>
        </Col>
        <Col xs="6">
          <Button style={{ width: "100%", maxWidth: 250, float: "right" }} color="info" onClick={clearCache}>
            Clear Cache
          </Button>
        </Col>
      </Row>
      <Formik
        initialValues={{
          new_isbn: ""
        }}
        validate={(values: any) => {
          let errors: any = {};
          if (!values.new_isbn) {
            errors.new_isbn = "ISBN is Required";
          }
          if (bundleISBNs.includes(values.new_isbn)) {
            errors.new_isbn = "ISBN Duplicated!";
          }
          return errors;
        }}
        onSubmit={addNewIsbnHandler}
      >
        {(formProps) => (
          <Form>
            <Row>
              <Col xs="12" md="3" className="custom_formik_col">
                <Label>ISBN</Label>
                <Field type="text" name="new_isbn" placeholder="ISBN" />
                {/* <ErrorMessage name="book_isbn" className="formik_error" /> */}
                <ErrorMessage name="new_isbn">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
              </Col>
              <Col xs="12" md="3" className="custom_formik_col">
                <Button type="submit" style={{ marginTop: 20 }} color="primary" block>
                  + ISBN
                </Button>
              </Col>
              <Col xs="12" md="3" className="custom_formik_col">
                <Button style={{ marginTop: 20 }} color="warning" block onClick={clearIsbnList}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>

        )}
      </Formik>
      {bundleISBNs.length > 0 && (
        <Row className="seprated_row">
          <Col xs="12">Bundle ISBNS:{JSON.stringify(bundleISBNs)}</Col>
          <Col xs="6" md="2">
            <Button style={{ marginTop: 10 }} color="success" block onClick={createNewBundleHandler}>
              + New Bundle
            </Button>
          </Col>
          <Col>{postLoading && <Spinner color="primary" />}</Col>
        </Row>
      )}

      <hr />
      {listLoading === true && (
        <Row>
          <Col>
            <Spinner color="primary" />
          </Col>
        </Row>
      )}
      {listLoading === false && bundleListResult.length > 0 && (
        <table className="bundle_table">
          <tbody>
            {bundleListResult.map((rowData: any, index) => {
              return (
                <tr key={rowData.b_id + "_row"}>
                  <td>{rowData.b_id}</td>
                  <td>{rowData.book_isbn}</td>
                  <td>{dateFormat(new Date(rowData.b_timestamp), "yyyy-mm-dd")}</td>
                  <td>{rowData.b_isbn}</td>
                  <td>{rowData.b_cat}</td>
                  <td>{rowData.b_language}</td>
                  <td>
                    <Button
                      color="danger"
                      block
                      onClick={() => {
                        deleteBundleHandler(rowData);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                  <td>
                    <a href={"http://www.bookurve.com:8080/cms/cms_book?action=book_search&book_isbn=" + rowData.book_isbn} target="_blank">
                      ⇨
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Container>
  );
};

export default Bundle;
