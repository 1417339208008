import BookApi from "../../../api/bookApi";
import * as actionType from "../actionType";
const bookAPI = new BookApi();

const getBatchIdInit = () => {
  return {
    type: actionType.GET_BATCHID_INIT
  };
};

const getBatchIdFailure = (err: any) => {
  return {
    type: actionType.GET_BATCHID_FAILURE,
    payload: err
  };
};

const getBatchIdSuccess = (result: any) => {
  return {
    type: actionType.GET_BATCHID_SUCCESS,
    payload: result
  };
};

export const getBatchId = (data): any => {
  return (dispatch: any) => {
    dispatch(getBatchIdInit());

    bookAPI
      .getBatchId(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBatchIdSuccess(result.data));
        } else {
          dispatch(getBatchIdFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBatchIdFailure(err));
      });
  };
};

const getBatchItemSearchInit = () => {
  return {
    type: actionType.GET_SEA_BTC_ITM_INIT
  };
};

const getBatchItemSearchFailure = (err: any) => {
  return {
    type: actionType.GET_SEA_BTC_ITM_FAILURE,
    payload: err
  };
};

const getBatchItemSearchSuccess = (result: any) => {
  return {
    type: actionType.GET_SEA_BTC_ITM_SUCCESS,
    payload: result
  };
};

export const getBatchItemSearch = (data): any => {
  return (dispatch: any) => {
    dispatch(getBatchItemSearchInit());

    bookAPI
      .getSearchISBNorUID(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBatchItemSearchSuccess(result.data));
        } else {
          dispatch(getBatchItemSearchFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBatchIdFailure(err));
      });
  };
};

const postBatchItemInit = () => {
  return {
    type: actionType.POST_BTC_ITM_INIT
  };
};

const postBatchItemFailure = (err: any) => {
  return {
    type: actionType.POST_BTC_ITM_FAILURE,
    payload: err
  };
};

const postBatchItemSuccess = (result: any) => {
  return {
    type: actionType.POST_BTC_ITM_SUCCESS,
    payload: result
  };
};

export const postBatchItem = (data): any => {
  return (dispatch: any) => {
    dispatch(postBatchItemInit());

    bookAPI
      .saveBatchItem(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postBatchItemSuccess(result.data));
        } else {
          dispatch(postBatchItemFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postBatchItemFailure(err));
      });
  };
};

const getBatchItemsInit = () => {
  return {
    type: actionType.GET_BAT_ITM_INIT
  };
};

const getBatchItemsFailure = (err: any) => {
  return {
    type: actionType.GET_BAT_ITM_FAILURE,
    payload: err
  };
};

const getBatchItemsSuccess = (result: any) => {
  return {
    type: actionType.GET_BAT_ITM_SUCCESS,
    payload: result
  };
};

export const getBatchItems = (data): any => {
  return (dispatch: any) => {
    dispatch(getBatchItemsInit());

    bookAPI
      .getBatchItems(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBatchItemsSuccess(result.data));
        } else {
          dispatch(getBatchItemsFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBatchItemsFailure(err));
      });
  };
};

const deleteBatchItemInit = () => {
  return {
    type: actionType.DELETE_BAT_ITM_INIT
  };
};

const deleteBatchItemFailure = (err: any) => {
  return {
    type: actionType.DELETE_BAT_ITM_FAILURE,
    payload: err
  };
};

const deleteBatchItemSuccess = (result: any) => {
  return {
    type: actionType.DELETE_BAT_ITM_SUCCESS,
    payload: result
  };
};

export const deleteBatchItem = (data): any => {
  return (dispatch: any) => {
    dispatch(deleteBatchItemInit());

    bookAPI
      .deleteBatchItem(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(deleteBatchItemSuccess(result.data));
        } else {
          dispatch(deleteBatchItemFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(deleteBatchItemFailure(err));
      });
  };
};

const getBatchListInit = () => {
  return {
    type: actionType.GET_BAT_LST_INIT
  };
};

const getBatchListFailure = (err: any) => {
  return {
    type: actionType.GET_BAT_LST_FAILURE,
    payload: err
  };
};

const getBatchListSuccess = (result: any) => {
  return {
    type: actionType.GET_BAT_LST_SUCCESS,
    payload: result
  };
};

export const getBatchList = (data): any => {
  return (dispatch: any) => {
    dispatch(getBatchListInit());

    bookAPI
      .getBatchList(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(getBatchListSuccess(result.data));
        } else {
          dispatch(getBatchListFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(getBatchListFailure(err));
      });
  };
};

const postBatchCnfStatusInit = () => {
  return {
    type: actionType.POST_BTC_CNF_STATUS_INIT
  };
};

const postBatchCnfStatusFailure = (err: any) => {
  return {
    type: actionType.POST_BTC_CNF_STATUS_FAILURE,
    payload: err
  };
};

const postBatchCnfStatusSuccess = (result: any) => {
  return {
    type: actionType.POST_BTC_CNF_STATUS_SUCCESS,
    payload: result
  };
};

export const postBatchCnfStatus = (data): any => {
  return (dispatch: any) => {
    dispatch(postBatchCnfStatusInit());

    bookAPI
      .postBatchConfirmStatus(data)
      .then((result: any) => {
        if (result.status === "success") {
          dispatch(postBatchCnfStatusSuccess(result.data));
        } else {
          dispatch(postBatchCnfStatusFailure(result.data));
        }
      })
      .catch((err: any) => {
        dispatch(postBatchCnfStatusFailure(err));
      });
  };
};