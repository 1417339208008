import * as React from "react";
import "./addBookSingle.css";

import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";

import MessageModal from "../../ui/messageModal/messageModal";

type Props = {
  dataSyncHandler: any;
  postBookDataResult: any;
};

const AddBookSingle: React.FC<Props> = (props: any) => {
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  let postBookObj = {
    book_isbn: "",
    book_title: "",
    book_author: "",
    book_category: "",
    book_seller: "",
    book_publisher: "",
    book_pub_date: "",
    book_format: "p",
    book_price: "",
    book_currency: "myr",
    book_country: "my",
    book_language: "en",
    book_image: "",
    book_synopsis: "",
    book_discount_rate: "",
    book_weight: ""
  };

  const handleSubmit = (values, actions) => {
    values.book_category = "-" + values.book_category + "-";
    props.dataSyncHandler(values);
  };

  const closeModalHandler = () => {
    setMessage("");
    setShowMessage(false);
  };

  React.useEffect(() => {
    if (props.postBookDataResult && props.postBookDataResult.book_isbn) {
      let postResult = props.postBookDataResult;
      if (postResult.server_flag === 3) {
        // Duplicated
        setMessage("Book Exist!");
        setShowMessage(true);
      } else if (postResult.server_flag === 1) {
        // Done
        setMessage("New Book Added Successfully.");
        setShowMessage(true);
      } else {
        // ERROR
        setMessage("Server Error!");
        setShowMessage(true);
      }
    }
  }, [props.postBookDataResult]);

  return (
    <Formik
      initialValues={postBookObj}
      validate={(values: any) => {
        let errors: any = {};

        if (!values.book_isbn) {
          errors.book_isbn = "ISBN is Required";
        }
        if (!values.book_title) {
          errors.book_title = "Title is Required";
        }
        if (!values.book_author) {
          errors.book_author = "Author is Required";
        }
        return errors;
      }}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form>
          <MessageModal closeModalHandler={closeModalHandler} showModal={showMessage} message={message} />
          <Row style={{paddingBottom: "20px"}}>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>ISBN</Label>
              <Field type="text" name="book_isbn" placeholder="ISBN" />
              {/* <ErrorMessage name="book_isbn" className="formik_error" /> */}
              <ErrorMessage name="book_isbn">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Title</Label>
              <Field type="text" name="book_title" placeholder="Title" />
              <ErrorMessage name="book_title">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Author</Label>
              <Field type="text" name="book_author" placeholder="Author" />
              <ErrorMessage name="book_author">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
          </Row>
          <Row className="seprated_row">
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Category</Label>
              <Field type="text" name="book_category" placeholder="Category" />
              <ErrorMessage name="book_category" className="" />
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Seller</Label>
              <Field name="book_seller" component="select" placeholder="Seller">
                <option value="">Select Seller</option>
                <option value="amz">Amazon</option>
                <option value="mph">MPH</option>
                <option value="png">Penguin</option>
                <option value="pan">Pansing</option>
                <option value="bk">Bookurve</option>
                <option value="dd">China</option>
              </Field>
              <ErrorMessage name="book_seller">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Publisher</Label>
              <Field type="text" name="book_publisher" placeholder="Publisher" />
              <ErrorMessage name="book_publisher">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Publish Date</Label>
              <Field type="text" name="book_pub_date" placeholder="Pub Date: yyyy-mm-dd" />
              <ErrorMessage name="book_pub_date">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Format</Label>
              <Field name="book_format" component="select" placeholder="Format">
                <option value="">Select Format</option>
                <option value="p">Paperback</option>
                <option value="h">Hardcover</option>
                <option value="m">Mass-paperback</option>
                <option value="b">Board Book</option>
              </Field>
              <ErrorMessage name="book_format">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Price</Label>
              <Field type="text" name="book_price" placeholder="" />
              <ErrorMessage name="book_price">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Discount Rate</Label>
              <Field type="text" name="book_discount_rate" placeholder="" />
              <ErrorMessage name="book_discount_rate">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Weight</Label>
              <Field type="text" name="book_weight" placeholder="" />
              <ErrorMessage name="book_weight">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Currency</Label>
              <Field name="book_currency" component="select" placeholder="Currency">
                <option value="myr">MYR</option>
                <option value="usd">USD</option>
                <option value="cny">YUAN</option>
              </Field>
              <ErrorMessage name="book_currency">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Country</Label>
              <Field name="book_country" component="select" placeholder="Country">
                <option value="my">Malaysia</option>
                <option value="us">US</option>
                <option value="cn">China</option>
              </Field>
              <ErrorMessage name="book_country">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Language</Label>
              <Field name="book_language" component="select" placeholder="Language">
                <option value="en">English</option>
                <option value="bn">Malay</option>
                <option value="cn">Chinese</option>
              </Field>
              <ErrorMessage name="book_language">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="3" className="custom_formik_col">
              <Label>Image</Label>
              <Field type="text" name="book_image" placeholder="Image" />
              <ErrorMessage name="book_image">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
            <Col xs="12" md="12" className="custom_formik_col">
              <Label>Synopsis</Label>
              <Field component="textarea" name="book_synopsis" placeholder="Synopsis" />
              <ErrorMessage name="book_synopsis">{(msg: any) => <div className="formik_error">{msg}</div>}</ErrorMessage>
            </Col>
          </Row>
          <br />
          <Button color="primary" type="submit">Submit Form</Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddBookSingle;
